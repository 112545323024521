import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { Button, makeStyles, Typography } from '@material-ui/core';
import QuickTradeModal from "./components/QuickTradeModal";
import EliteKycStatus from "../KycStatus/EliteKycStatus";
import TradeInfo from './components/TradeInfo'
import { useDispatch, useSelector } from "react-redux";
import { kycActions } from "../../redux/actions/kycActions";
import { authActions } from "../../redux/actions/authActions";
import { fundsActions } from "../../redux/actions/fundsActions";
import { tradeActions } from "../../redux/actions/tradeActions";
import { formDateString } from "../../utils/ConvertDate";
import { roundingDown } from "../../utils/RoundingDown";
import {convertDateString, FormatNumber, getIcons} from "../../utils/Common";
import ResultModal from "../ResultModal/ResultModal";
import TradeConfirmModal from "./components/TradeConfirmModal";
import arrorTo from "../../images/arrorTo.png"
import warningLabel from '../../images/warningLabel.png'
import questionIcon from "../../images/questionIcon.png"
import i18n from '../../i18n';
import { Helmet } from "react-helmet";
import arrorToBlack from "../../images/toArrowBlack.png"
import depositIcon from "../../images/depositIcon.png"
import withdrawIcon from "../../images/withdrawIcon.png"

let timeout;

function QuickTrade({ t, width, height }) {
    const { token, userInfo, loggedIn } = useSelector(state => state.auth);
    const { userCapitals, priceQuote, ethQuote, btcQuote } = useSelector(state => state.funds);
    const { kycDetails, fetched } = useSelector(state => state.kyc);
    const { tradeLoading, tradeSucceed, tradeFailed, tradeMsg, tradeData, tradePriceQuote, tradePriceQuoteLoading } = useSelector(state => state.trade);
    const [loaded, setLoaded] = useState(false)
    const [phone, setPhone] = useState(width <= 745);
    const [kycVisible, setKycVisible] = useState(false);
    const [initialing, setInitialing] = useState(true);
    const [confirmOrderVisible, setConfirmOrderVisible] = useState(false);
    const [orderSubmittedVisible, setOrderSubmittedVisible] = useState(false);
    const [payValue, setPayValue] = useState('');
    const [receiveValue, setReceiveValue] = useState('');
    const [coin1Selected, setCoin1Selected] = useState('CAD')
    const [coin2Selected, setCoin2Selected] = useState('USDT')
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [resultModalVisible, setResultModalVisible] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [selected, setSelected] = useState('buy');
    const [CADToUSDT, setCADToSUDT] = useState(0)
    const [responsive, setResponsive] = useState(false)
    const [canWithdraw, setCanWithdraw] = useState(false)

    const [quote, setQuote] = useState({
        ETH: ethQuote,
        BTC: btcQuote,
        USDT: priceQuote
    })

    const useStyles = makeStyles({
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: phone ? 12 : 16,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'left',
            textTransform: 'none'
        },
        selector: {
            height: phone ? 26 : 43,
            width: phone ? 70 : 90,
            borderRadius: phone ? 5 : 10,
            backgroundColor: '#162E83'
        },
        button: {
            width: '100%',
            height: phone ? 60 : 65,
            borderRadius: phone ? 12 : 20,
            backgroundColor: '#162E83 !important',
            textTransform: 'none',
            marginTop: phone ? 35 : 60
        },
        selectorIcon: {
            paddingRight: phone ? '18px !important' : null
        },
        operationButton: {
            alignSelf: 'center',

            height: '46px',
            borderRadius: 12,
            color: 'white',
            backgroundColor: '#0173F9',
            fontFamily: 'Avenir',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#162E83'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#162E83',
                },
            }
        },
        operationButtonPhone: {

            height: '32px',
            borderRadius: 8,
            minWidth: 0,
            minHeight: 0,
            backgroundColor: '#162E83',
            fontFamily: 'Avenir',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#162E83',
                },
            }
        },
    })
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const getTradeQuote = () => {
        dispatch(tradeActions.tradePriceQuote(
            {
                token1: coin1Selected,
                token2: coin2Selected,
            },
            {
                "deliverQuantity": payValue,
                "tradeSide": selected
            }, token));
    }
    const expireTradeQuote = () => {
        dispatch(tradeActions.expireAuaQuote(tradePriceQuote.quoteId, token));
    }
    const stopTimeLeft = () => {
        setRefreshing(false)
        setTimeLeft(-1);
        clearTimeout(timeout)
    }
    useEffect(() => {
        dispatch(tradeActions.clearTradeQuote());
        dispatch(authActions.getUserInfo(token))
        dispatch(kycActions.syncKycStatus(token))
        dispatch(kycActions.syncAddressStatus(token))
        dispatch(fundsActions.getUserCapital(token))
        dispatch(fundsActions.priceQuote({
            "ticker": "USDT-CAD",
            "deliverQuantity": "1",
            "tradeSide": "sell"
        }, token))
        dispatch(fundsActions.getETHQuote({
            "ticker": "ETH-CAD",
            "deliverQuantity": "0.01",
            "tradeSide": "sell"
        }, token))
        dispatch(fundsActions.getBTCQuote({
            "ticker": "BTC-CAD",
            "deliverQuantity": "0.01",
            "tradeSide": "sell"
        }, token))
        setLoaded(true)
        return () => {
        }
    }, []);
    useEffect(() => {
        // console.log('userInfo: ', userInfo)
        setCanWithdraw(userInfo.phoneValid)
    }, [userInfo])
    useEffect(() => {
        dispatch(fundsActions.priceQuote({
            "ticker": "USDT-CAD",
            "deliverQuantity": "1",
            "tradeSide": "sell"
        }))
        dispatch(fundsActions.getETHQuote({
            "ticker": "ETH-CAD",
            "deliverQuantity": "0.01",
            "tradeSide": "sell"
        }))
        dispatch(fundsActions.getBTCQuote({
            "ticker": "BTC-CAD",
            "deliverQuantity": "0.01",
            "tradeSide": "sell"
        }))
    }, [loggedIn])

    useEffect(() => {
        setPhone(width <= 745)
    }, [width]);

    useEffect(() => {
        if (fetched) {
            dispatch(kycActions.getKycDetails(token))
        }
    }, [fetched])

    useEffect(() => {
        if (initialing) {
            setInitialing(false)
        } else {
            if (!userInfo.lastName) {
                history.push("/verification")
            }
        }
        return () => {
        }
    }, [userInfo])

    useEffect(() => {
        if (tradeLoading === false) {
            if (tradeFailed) {
                stopTimeLeft();
                setSnackBarMessage(t(tradeMsg));
                setConfirmOrderVisible(false);
                setResultModalVisible(true);
                setPayValue('');
                setReceiveValue('');
            } else if (tradeSucceed) {
                stopTimeLeft();
                setConfirmOrderVisible(false);
                setOrderSubmittedVisible(true);
            }
            dispatch(tradeActions.clearTrade());
            dispatch(tradeActions.clearTradeQuote());
        }
        return () => {
        }
    }, [tradeLoading])

    useEffect(() => {
        if (refreshing) {
            if (timeLeft === 0) {
                expireTradeQuote()
                getTradeQuote()
                setTimeLeft(7)
            } else {
                timeLeft > 0 && (timeout = setTimeout(() => setTimeLeft(timeLeft - 1), 1000));
            }
        }
    }, [timeLeft]);

    useEffect(() => {
        if (!initialing && tradePriceQuote && tradePriceQuote.receiveQuantity) {
            setReceiveValue(roundingDown(tradePriceQuote.receiveQuantity, 8))
        }
        return () => {
        }
    }, [tradePriceQuote])

    useEffect(() => {
        setCADToSUDT(priceQuote.price)
    }, [priceQuote, userCapitals])

    useEffect(() => {
        setResponsive(width <= 800)
    }, [width])

    useEffect(() => {
        setQuote({
            ETH: ethQuote,
            BTC: btcQuote,
            USDT: priceQuote
        })
    }, [priceQuote, ethQuote, btcQuote])

    // useEffect(() => {
    //     console.log('kycDetails: ', kycDetails )
    // }, [kycDetails])


    const goToFund = () => {
        history.push('/fund')
    }

    const goToWithdraw = () => {


        history.push('/withdraw')
    }

    const renderHeader = () => {
        let hideWarning = kycDetails.status === 'passed' && userCapitals.length > 0
        let USDTBalance = userCapitals.find(item => item.symbol === 'USDT')
        let CADBalance = userCapitals.find(item => item.symbol === 'CAD')
        let ETHBalance = userCapitals.find(item => item.symbol === 'ETH')
        let BTCBalance = userCapitals.find(item => item.symbol === 'BTC')

        if (USDTBalance === undefined) {
            USDTBalance = { amount: 0 }
        }
        if (CADBalance === undefined) {
            CADBalance = { amount: 0 }
        }
        if (ETHBalance === undefined) {
            ETHBalance = { amount: 0 }
        }
        if (BTCBalance === undefined) {
            BTCBalance = { amount: 0 }
        }
        let totalBalance = CADToUSDT * USDTBalance.amount + CADBalance.amount + ETHBalance.amount * ethQuote.price + BTCBalance.amount * btcQuote.price
        console.log(BTCBalance.amount * btcQuote.price)
        return (
            hideWarning ?
                phone ?
                    <div style={{}}>

                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-start', marginBottom: 16 }}>
                            <Typography style={{ alignSelf: 'flex-start', fontSize: 24, fontWeight: 500, marginTop: 16, fontFamily: 'Avenir' }}>
                                {`Hi, ${userInfo.firstName}`}
                            </Typography>

                            <div
                                style={
                                    { backgroundColor: kycDetails.status === 'passed' ? "#0CB451" : "#DC3055", borderRadius: 4, marginLeft: 16, marginTop: 8 }

                                }
                            >
                                <Typography style={{ fontSize: 10, fontWeight: '500', color: "white", marginLeft: 6, marginRight: 6 }}>
                                    {kycDetails.status === 'passed' ? t('accountVerified') : t('notVerified')}
                                </Typography>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <div>
                                    <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', }}>{t('estimated')}</Typography>

                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <Typography style={{
                                            color: '#003FFF',
                                            fontFamily: 'Avenir',
                                            fontSize: phone ? 20 : 20,
                                            fontWeight: 500,
                                            letterSpacing: 0,
                                            textAlign: 'left',
                                            textTransform: 'none'
                                        }}>${FormatNumber(totalBalance)} </Typography>
                                        <Typography style={{ fontSize: phone ? 10 : 14, color: '#003FFF', fontWeight: '500', marginBottom: phone ? 3 : 2, marginLeft: phone ? 4 : 8, fontFamily: 'Avenir', }}>CAD </Typography>
                                    </div>

                                </div>
                                <div>



                                    <Button
                                        className={classes.operationButtonPhone}
                                        style={{ color: '#1C2237', backgroundColor: "#D7E2FF", opacity: !loaded || kycDetails.status !== 'passed' ? 0.6 : 1, marginLeft: 8 }}
                                        disabled={!loaded || kycDetails.status !== 'passed'}
                                        onClick={() => { goToFund() }}
                                    >
                                        <img style={{ height: 24, width: 24 }} src={depositIcon}></img>
                                        {t('getFund')}
                                    </Button>
                                </div>

                            </div>

                            <div style={{ display: 'flex', marginTop: 24, }}>

                                {
                                    CADBalance.amount > 0 ?
                                        <div style={{ backgroundColor: '#EEEEEE', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 8, borderRadius: 8, marginLeft: 4, marginRight: 4, display: 'flex', flexDirection: "column" }}>
                                            <Typography style={{ fontSize: phone ? 10 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', marginLeft: 8, marginTop: 4 }}>CAD</Typography>
                                            <Typography style={{ fontSize: phone ? 10 : 14, color: '#3254FA', fontWeight: '600', fontFamily: 'Avenir', marginLeft: 8, marginBottom: 4, marginRight: 8 }}>{roundingDown(CADBalance.amount, 2)}   </Typography>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    USDTBalance.amount * CADToUSDT > 1 ?
                                        <div style={{ backgroundColor: '#EEEEEE', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 8, borderRadius: 8, marginLeft: 4, marginRight: 4, display: 'flex', flexDirection: "column" }}>
                                            <Typography style={{ fontSize: phone ? 10 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', marginLeft: 8, marginTop: 4 }}>USDT</Typography>

                                            <Typography style={{ fontSize: phone ? 10 : 14, color: '#3254FA', fontWeight: '600', fontFamily: 'Avenir', marginLeft: 8, marginBottom: 4, marginRight: 8 }}>{roundingDown(USDTBalance.amount, 2)}   </Typography>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    ETHBalance.amount * ethQuote.price > 1 ?
                                        <div style={{ backgroundColor: '#EEEEEE', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 8, borderRadius: 8, marginLeft: 4, marginRight: 4, display: 'flex', flexDirection: "column" }}>
                                            <Typography style={{ fontSize: phone ? 10 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', marginLeft: 8, marginTop: 4 }}>ETH</Typography>
                                            <Typography style={{ fontSize: phone ? 10 : 14, color: '#3254FA', fontWeight: '600', fontFamily: 'Avenir', marginLeft: 8, marginBottom: 4, marginRight: 8 }}>{roundingDown(ETHBalance.amount, 4)}   </Typography>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    BTCBalance.amount * btcQuote.price > 1 ?
                                        <div style={{ backgroundColor: '#EEEEEE', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 8, borderRadius: 8, marginLeft: 4, marginRight: 4, display: 'flex', flexDirection: "column" }}>
                                            <Typography style={{ fontSize: phone ? 10 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', marginLeft: 8, marginTop: 4 }}>BTC</Typography>
                                            <Typography style={{ fontSize: phone ? 10 : 14, color: '#3254FA', fontWeight: '600', fontFamily: 'Avenir', marginLeft: 8, marginBottom: 4, marginRight: 8 }}>{roundingDown(BTCBalance.amount, 4)}   </Typography>
                                        </div>
                                        :
                                        null
                                }

                                <Button style={{ backgroundColor: '#EEEEEE', alignItems: 'center', justifyContent: 'center', marginBottom: 8, borderRadius: 8, marginLeft: 4, marginRight: 4, display: 'flex', flexDirection: "column" }} onClick={() => { history.push('/balance') }}>
                                    <Typography style={{ fontSize: phone ? 12 : 14, fontWeight: '500', fontFamily: 'Avenir', textTransform: 'none', color: '#8B96A7' }}>{t('detail')} </Typography>

                                </Button>


                            </div>
                        </div>
                        <div style={{ height: 1, width: '100%', backgroundColor: '#E5E5E5', marginBottom: 24, marginTop: 24 }} />



                    </div>
                    :
                    <div style={{ marginBottom: 24, backgroundColor: 'transparent' }}>

                        <div style={{ display: 'flex', backgroundColor: '#f9f9f9', justifyContent: 'space-between', alignItems: 'center', marginBottom: phone ? 16 : 24, paddingLeft: 24, paddingRight: 24, borderRadius: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>

                                <div style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                        <div >
                                            <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', marginTop: 24 }}>{t('estimated')}</Typography>

                                            <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: -4 }}>
                                                <Typography style={{
                                                    color: '#003FFF',
                                                    fontFamily: 'Avenir',
                                                    fontSize: phone ? 20 : 32,
                                                    fontWeight: 500,
                                                    letterSpacing: 0,
                                                    textAlign: 'left',
                                                    textTransform: 'none'
                                                }}>${FormatNumber(totalBalance)} </Typography>
                                                <Typography style={{ fontSize: phone ? 10 : 14, color: '#003FFF', fontWeight: '500', marginBottom: phone ? 3 : 8, marginLeft: phone ? 4 : 8, fontFamily: 'Avenir', }}>CAD </Typography>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'flex-end' }}>



                                            <Button
                                                className={phone ? classes.operationButtonPhone : classes.operationButton}
                                                style={{ color: '#1C2237', backgroundColor: "#D7E2FF", opacity: !loaded || kycDetails.status !== 'passed' ? 0.6 : 1, marginLeft: 12 }}
                                                disabled={!loaded || kycDetails.status !== 'passed'}
                                                onClick={() => { goToFund() }}
                                            >
                                                <img style={{ height: 24 }} src={depositIcon}></img>
                                                {t('getFund')}
                                            </Button>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 24, marginTop: 24 }}>

                                        {
                                            CADBalance.amount > 0 ?
                                                <div style={{ backgroundColor: '#EEEEEE', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 8, borderRadius: 8, marginLeft: 4, marginRight: 4, display: 'flex', flexDirection: "column" }}>
                                                    <Typography style={{ fontSize: phone ? 10 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', marginLeft: 8, marginTop: 4 }}>CAD</Typography>
                                                    <Typography style={{ fontSize: phone ? 10 : 14, color: '#3254FA', fontWeight: '600', fontFamily: 'Avenir', marginLeft: 8, marginBottom: 4, marginRight: 8 }}>{roundingDown(CADBalance.amount, 2)}   </Typography>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            USDTBalance.amount * CADToUSDT > 1 ?
                                                <div style={{ backgroundColor: '#EEEEEE', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 8, borderRadius: 8, marginLeft: 4, marginRight: 4, display: 'flex', flexDirection: "column" }}>
                                                    <Typography style={{ fontSize: phone ? 10 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', marginLeft: 8, marginTop: 4 }}>USDT</Typography>

                                                    <Typography style={{ fontSize: phone ? 10 : 14, color: '#3254FA', fontWeight: '600', fontFamily: 'Avenir', marginLeft: 8, marginBottom: 4, marginRight: 8 }}>{roundingDown(USDTBalance.amount, 2)}   </Typography>
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            ETHBalance.amount * ethQuote.price > 1 ?
                                                <div style={{ backgroundColor: '#EEEEEE', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 8, borderRadius: 8, marginLeft: 4, marginRight: 4, display: 'flex', flexDirection: "column" }}>
                                                    <Typography style={{ fontSize: phone ? 10 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', marginLeft: 8, marginTop: 4 }}>ETH</Typography>
                                                    <Typography style={{ fontSize: phone ? 10 : 14, color: '#3254FA', fontWeight: '600', fontFamily: 'Avenir', marginLeft: 8, marginBottom: 4, marginRight: 8 }}>{roundingDown(ETHBalance.amount, 4)}   </Typography>
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            BTCBalance.amount * btcQuote.price > 1 ?
                                                <div style={{ backgroundColor: '#EEEEEE', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 8, borderRadius: 8, marginLeft: 4, marginRight: 4, display: 'flex', flexDirection: "column" }}>
                                                    <Typography style={{ fontSize: phone ? 10 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', marginLeft: 8, marginTop: 4 }}>BTC</Typography>
                                                    <Typography style={{ fontSize: phone ? 10 : 14, color: '#3254FA', fontWeight: '600', fontFamily: 'Avenir', marginLeft: 8, marginBottom: 4, marginRight: 8 }}>{roundingDown(BTCBalance.amount, 4)}   </Typography>
                                                </div>
                                                :
                                                null
                                        }

                                        <Button style={{ backgroundColor: '#EEEEEE', alignItems: 'center', justifyContent: 'center', marginBottom: 8, borderRadius: 8, marginLeft: 4, marginRight: 4, display: 'flex', flexDirection: "column" }} onClick={() => { history.push('/balance') }}>
                                            <Typography style={{ fontSize: phone ? 12 : 14, fontWeight: '500', fontFamily: 'Avenir', textTransform: 'none', color: '#8B96A7' }}>{t('detail')} </Typography>


                                        </Button>


                                    </div>

                                </div>

                            </div>


                        </div>


                    </div>



                :
                <EliteKycStatus phone={phone} setKycVisible={setKycVisible} userInfo={userInfo} responsive={responsive} width={width} />
        )
    }

    const renderTrade = () => {
        return (
            <div style={{ display: 'flex', flexDirection: responsive ? 'column' : 'row', alignItems: 'stretch', justifyContent: 'space-evenly', width: phone ? "91%" : "100%", marginTop: phone ? 8 : 32 }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: responsive ? "100%" : '46%', paddingRight: phone ? "0%" : '0%' }}>
                    {loaded ? renderHeader() : null}

                    <QuickTradeModal
                        phone={phone}
                        next={() => { setConfirmOrderVisible(true); setRefreshing(true); setTimeLeft(7); getTradeQuote() }}
                        payValue={payValue}
                        receiveValue={receiveValue}
                        setPayValue={setPayValue}
                        setReceiveValue={setReceiveValue}
                        coin1Selected={coin1Selected}
                        coin2Selected={coin2Selected}
                        setCoin1Selected={setCoin1Selected}
                        setCoin2Selected={setCoin2Selected}
                        selected={selected}
                        setSelected={setSelected}
                        homePage={false}
                    />
                </div>
                {
                    responsive ?
                        <div style={{ borderBottom: 'solid 1px #E4E3E3', marginTop: 35, marginBottom: 35 }} /> : <div style={{ borderLeft: 'solid 1px #E4E3E3' }} />
                }
                <div style={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'left', width: phone ? "100%" : '34%', paddingLeft: phone ? "0%" : '0%' }}>
                    <TradeInfo phone={phone} dispatch={dispatch} responsive={responsive} quote={quote} />
                </div>
            </div>
        )
    }

    const renderOrderSubmitted = () => {
        return (
            <div style={{ maxWidth: 543, justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: phone ? 0 : 30 }}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography
                            style={
                                phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                    : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                            }
                        >
                            {t('trade_orderSubmitted')}
                        </Typography>
                    </div>
                </div>
                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: phone ? 12 : 0 }} />
                <div style={{ display: 'flex', alignItems: 'center', marginTop: phone ? 8 : 24 }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <img style={{ width: phone ? 24 : 32, height: phone ? 24 : 32, borderRadius: phone ? 8 : 12, alignSelf: 'center', backgroundColor: '#F9F9F9', padding: phone ? 6 : 12 }} src={getIcons(coin1Selected)} />
                        <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: '500', color: '#979797', fontFamily: "Avenir" }}>{coin1Selected}</Typography>
                    </div>
                    <img style={{ height: phone ? 24 : 32, marginLeft: 8, marginRight: 16, marginBottom: 18 }} src={arrorTo} />
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                        <img style={{ width: phone ? 24 : 32, height: phone ? 24 : 32, borderRadius: phone ? 8 : 12, alignSelf: 'center', backgroundColor: '#F9F9F9', padding: phone ? 6 : 12 }} src={getIcons(coin2Selected)} />
                        <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: '500', color: '#979797', fontFamily: "Avenir" }}>{coin2Selected}</Typography>

                    </div>

                </div>


                <Typography style={{ color: '#BAC3CE', fontWeight: '500', fontSize: 16, marginTop: 30, fontFamily: "Avenir" }}>{t('trade_orderDetails')}</Typography>

                <div style={{ marginTop: 8, borderRadius: phone ? 12 : 20, backgroundColor: '#F9F9F9' }}>
                    <div style={{ margin: phone ? '21px 17px' : '35px 28px' }}>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <img alt='' src={otcLogo} style={phone ? { width: 49, height: 10 } : { width: 79, height: 16 }}></img>
                        </div> */}

                        {/* <div style={{ marginTop: phone ? 8 : 13, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD' }}></div> */}

                        <div className={classes.font} style={{ marginTop: phone ? 15 : 21, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className={classes.font} style={{ color: '#858E9D' }}>{t('trade_orderNumber')}</Typography>
                            <Typography className={classes.font} style={{ color: '#1C2237', textAlign: 'right' }}>{tradeData.reference}</Typography>
                        </div>

                        {
                            tradeData.type === 'buy' ?
                                <>
                                    <div style={{ marginTop: phone ? 13 : 18, display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography className={classes.font} style={{ color: '#858E9D' }}>{t('trade_orderBuyCrypto')}</Typography>
                                        <Typography className={classes.font} style={{ color: '#1C2237' }}>{roundingDown(tradeData.receiveQuantity, 8)} {tradeData.receiveCurrency}</Typography>
                                    </div>
                                    <div style={{ marginTop: phone ? 13 : 18, display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography className={classes.font} style={{ color: '#858E9D' }}>{t('trade_totalPayment')}</Typography>
                                        <Typography className={classes.font} style={{ color: '#1C2237' }}>${roundingDown(tradeData.deliverQuantity, 8)} {tradeData.deliverCurrency}</Typography>
                                    </div>
                                </>
                                : <>
                                    <div style={{ marginTop: phone ? 13 : 18, display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography className={classes.font} style={{ color: '#858E9D' }}>{t('trade_orderSellCrypto')}</Typography>
                                        <Typography className={classes.font} style={{ color: '#1C2237' }}>{roundingDown(tradeData.deliverQuantity, 8)} {tradeData.deliverCurrency}</Typography>
                                    </div>
                                    <div style={{ marginTop: phone ? 13 : 18, display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography className={classes.font} style={{ color: '#858E9D' }}>{t('trade_totalPayment')}</Typography>
                                        <Typography className={classes.font} style={{ color: '#1C2237' }}>${roundingDown(tradeData.receiveQuantity, 8)} {tradeData.receiveCurrency}</Typography>
                                    </div>
                                </>
                        }


                        <div style={{ marginTop: phone ? 13 : 18, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className={classes.font} style={{ color: '#858E9D' }}>{t('time')}</Typography>
                            <Typography className={classes.font} style={{ color: '#1C2237', textAlign: 'right' }}>{formDateString(tradeData.createdAt)} (UTC)</Typography>
                        </div>
                    </div>

                </div>
                <div style={{ display: 'flex', marginTop: 24 }}>
                    <img src={warningLabel} style={{ height: 24, width: 24, display: 'flex', flexDirection: 'row' }}></img>
                    <Typography className={classes.font} style={{ fontSize: phone ? 12 : 14, color: '#858E9D', fontWeight: 500, marginLeft: 8 }}>
                        {t('trade_orderSubmitted_text')}
                    </Typography>
                </div>
                <Button
                    className={`${classes.button} ${classes.font}`}
                    style={{ color: 'white', fontSize: phone ? 14 : 20, fontWeight: '500' }}
                    onClick={() => {
                        setOrderSubmittedVisible(false);
                        setPayValue('');
                        setReceiveValue('')
                        dispatch(tradeActions.getAquaBalance({
                            coin: coin1Selected
                        }, token))
                        dispatch(fundsActions.getUserCapital(token))
                    }}
                >
                    {t('done')}
                </Button>
            </div>
        )
    }

    return (


        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 80, paddingTop: phone ? 0 : 24 }}>
            <Helmet>
                <title>{t('titleTrade')}</title>
                <meta name="keywords" content="Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇, 加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣， Yesbit, Fiat, CAD, BTC, ETH, USD" />
            </Helmet>
            <div style={{ width: phone ? "100%" : '80%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {orderSubmittedVisible ?
                    renderOrderSubmitted()
                    : renderTrade()}
            </div>


            {/*交易结果弹窗*/}
            <ResultModal
                onCancel={() => {
                    setResultModalVisible(false)
                }}
                modalVisible={resultModalVisible}
                t
                width={width}
                phone={phone}
                title={t('tradingFailed')}
                content={snackBarMessage}
                resultSuccess={false}
            />
            {/*确认交易弹窗*/}
            <TradeConfirmModal
                onCancel={() => {
                    if (!tradePriceQuoteLoading && !tradeLoading) {
                        expireTradeQuote();
                        setConfirmOrderVisible(false);
                        stopTimeLeft();
                        dispatch(tradeActions.clearTradeQuote());
                        dispatch(tradeActions.getAquaBalance({
                            coin: coin1Selected
                        }, token))
                        dispatch(fundsActions.getUserCapital(token))
                        setPayValue('');
                        setReceiveValue('');
                    }
                }}
                modalVisible={confirmOrderVisible}

                t
                phone={phone}
                payValue={payValue}
                receiveValue={receiveValue}
                coin1Selected={coin1Selected}
                coin2Selected={coin2Selected}
                selected={selected}
                timeLeft={timeLeft}
                stopTimeLeft={() => {
                    setRefreshing(false);
                    clearTimeout(timeout)
                }}
            />
        </div>
    );
}

export default withTranslation()(QuickTrade);
