import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'
import { useHistory, useLocation } from 'react-router-dom';
import navLogo from '../images/nav/NavLogo.svg';
import i18n from '../i18n';
import { withTranslation } from 'react-i18next';
import yesbitIconBlack from '../images/home/yesbitIconBlack.png';
import yesbitComLogo from '../images/home/yesbitComLogo.png';

import earnIcon from '../images/home/earnIcon.png';
import moreIcon from '../images/moreIcon.png';
import tutorialPhoneIcon from '../images/home/tutorialPhoneIcon.png';
import yesbitIconBlue from '../images/home/yesbitIconBlue.png';

import wealthIcon from '../images/home/wealthIcon.png';

import NFTPhoneIcon from '../images/home/NFTPhoneIcon.png';
import ContactPhoneIcon from '../images/home/ContactPhoneIcon.png';
import rewardPhoneIcon from '../images/home/rewardPhoneIcon.png';
import ChinaIcon from '../images/home/ChinaIcon.png';
import USIcon from '../images/home/USIcon.png';
import { checkRoutes } from "../utils/CheckRoutes";

import './Navbar.css'
import {
    Button,
    Typography,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Divider,
    makeStyles,
    ListItem,
    IconButton
} from '@material-ui/core';
function Navbar({ t, width }) {

    const [phone, setPhone] = useState(width <= 745);
    useEffect(() => {
        setPhone(width <= 745)


    }, [width]);
    let history = useHistory();
    let location = useLocation()
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)
    const [isChinese, setIsChinese] = useState(true)
    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)
    const useStyles = makeStyles({
        button: {
            minWidth: 0, textTransform: 'none', marginLeft: 12, marginRight: 12
        },
        fontStyle: {
            fontSize: width < 1500 ? 14 : 16, fontFamily: "Avenir", fontWeight: '500'
        },
        popTitle: {
            fontSize: 14, fontWeight: '500', fontFamily: 'Avenir'
        },
        menuItemStyle: {
            justifyContent: 'flex-start', padding: 24, marginLeft: 16, marginRight: 16
        }
    })
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const classes = useStyles();
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }



    const changeLanguage = (isChinese) => {
        if (isChinese) {
            i18n.changeLanguage("cn");
            localStorage.setItem('lng', "cn")
        } else {
            i18n.changeLanguage("en");
            localStorage.setItem('lng', "en")

        }
        setIsChinese(!isChinese)
    }

    window.addEventListener('resize', showButton)

    useEffect(() => {
        console.log('here: ', window.location, process.env.REACT_APP_ENV)
        checkRoutes()
    }, [history, location])
    return (
        <>
            {phone ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <div style={{ width: '95%', display: 'flex', flexDirection: 'row', marginTop: 32, marginBottom: 16, height: 52, justifyContent: 'space-between', alignItems: 'center' }}>

                        <Link to='/' onClick={closeMobileMenu}>
                            <img alt="" src={yesbitComLogo} style={{ width: 80 }} />
                        </Link>

                        <Button ref={anchorRef} onClick={handleToggle} style={{ minHeight: '100%', textTransform: 'none' }}>

                            <img alt='' src={moreIcon} style={{ width: 30, height: 30, backgroundColor: '##F6F7FA', borderRadius: 14 }} />

                        </Button>

                        <Popper
                            style={{ marginTop: 16, width: '100%', display: 'flex', justifyContent: 'center' }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="top-end"
                            // placement="bottom-end"
                            transition
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}

                                    in={open}

                                >
                                    <Paper elevation={8} style={{ backgroundColor: "white", width: '90%', borderRadius: 20, padding: 0, overflow: 'hidden' }}>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                            >


                                                <MenuItem className={classes.menuItemStyle} onClick={() => { history.push('/nft-homepage'); handleToggle() }}>
                                                    {/* <img alt='' src={settingIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} /> */}
                                                    <img src={NFTPhoneIcon} style={{ height: 24, width: 24, marginRight: 16 }}></img>

                                                    <Typography className={classes.popTitle}>
                                                        NFT
                                                    </Typography>
                                                </MenuItem>
                                                <div style={{ height: 1, backgroundColor: '#F2F2F2', marginLeft: 24, marginRight: 24 }}></div>

                                                <MenuItem className={classes.menuItemStyle} onClick={() => { window.open("https://wealth.yesbit.io"); handleToggle() }}>
                                                    <img src={wealthIcon} style={{ height: 24, width: 24, marginRight: 16 }}></img>

                                                    <Typography className={classes.popTitle}>
                                                        {t('navBar_Wealth')}
                                                    </Typography>
                                                </MenuItem>



                                                <div style={{ height: 1, backgroundColor: '#F2F2F2', marginLeft: 24, marginRight: 24 }}></div>
                                                <MenuItem className={classes.menuItemStyle}
                                                    onClick={() => { history.push('/referral-info'); handleToggle() }}
                                                >
                                                    {/* <img alt='' src={settingIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} /> */}
                                                    <img src={rewardPhoneIcon} style={{ height: 24, width: 24, marginRight: 16 }}></img>

                                                    <Typography className={classes.popTitle}>
                                                        {t('navBar_Commission')}
                                                    </Typography>

                                                </MenuItem>
                                                <div style={{ height: 1, backgroundColor: '#F2F2F2', marginLeft: 24, marginRight: 24 }}></div>
                                                <MenuItem className={classes.menuItemStyle} onClick={() => { window.open(localStorage.getItem('lng') === 'cn' ? "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/ " : "https://otcdocs.yesbit.io/"); handleToggle() }}>
                                                    {/* <img alt='' src={settingIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} /> */}
                                                    <img src={tutorialPhoneIcon} style={{ height: 24, width: 24, marginRight: 16 }}></img>

                                                    <Typography className={classes.popTitle}>
                                                        {t('navBar_Tutorials')}
                                                    </Typography>

                                                </MenuItem>
                                                <div style={{ height: 1, backgroundColor: '#F2F2F2', marginLeft: 24, marginRight: 24 }}></div>

                                                {/* <MenuItem className={classes.menuItemStyle}
                                                    onClick={() => {
                                                        window.scrollTo({
                                                            top: document.documentElement.scrollHeight,
                                                            behavior: 'smooth'

                                                        });
                                                        handleToggle()
                                                    }}
                                                >
                                                    <img src={ContactPhoneIcon} style={{ height: 24, width: 24, marginRight: 16 }}></img>

                                                    <Typography className={classes.popTitle}>
                                                        {t('navBar_Contact')}
                                                    </Typography>
                                                </MenuItem>
                                                <div style={{ height: 1, backgroundColor: '#F2F2F2', marginLeft: 24, marginRight: 24 }}></div> */}



                                                <MenuItem className={classes.menuItemStyle}
                                                    onClick={() => { history.push('/about-us'); handleToggle() }}
                                                >
                                                    {/* <img alt='' src={settingIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} /> */}
                                                    <img src={yesbitIconBlue} style={{ height: 24, width: 24, marginRight: 16 }}></img>

                                                    <Typography className={classes.popTitle}>
                                                        {t('aboutUs')}
                                                    </Typography>

                                                </MenuItem>
                                                <div style={{ height: 1, backgroundColor: '#F2F2F2', marginLeft: 24, marginRight: 24 }}></div>


                                                <MenuItem className={classes.menuItemStyle} onClick={() => changeLanguage(isChinese)}>
                                                    {/* <img alt='' src={settingIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} /> */}
                                                    <img src={localStorage.getItem('lng') === 'en' ? ChinaIcon : USIcon} style={{ height: 24, width: 24, marginRight: 16 }}></img>

                                                    <Typography className={classes.popTitle}>
                                                        {localStorage.getItem('lng') === 'en' ? "中文" : "English"}

                                                    </Typography>

                                                </MenuItem>
                                                <div style={{ height: 1, backgroundColor: '#F2F2F2', marginLeft: 24, marginRight: 24, marginBottom: 24 }}></div>



                                                <MenuItem className={classes.menuItemStyle} onClick={() => {
                                                    window.location.replace(process.env.REACT_APP_ENV ==='prod' ? 'https://trade.yesbit.io/register' : '/register')
                                                }}>
                                                    {/* <img alt='' src={settingIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} /> */}
                                                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#3254FA', width: "100%", height: 48, alignItems: 'center', justifyContent: 'center', borderRadius: 16 }}>
                                                        <Typography className={classes.popTitle} style={{ color: 'white' }}>
                                                            {t('footer_col1Sub3')}

                                                        </Typography>
                                                    </div>
                                                </MenuItem>

                                                <MenuItem className={classes.menuItemStyle} onClick={() => {
                                                    window.location.replace(process.env.REACT_APP_ENV ==='prod' ? 'https://trade.yesbit.io/login' : '/login')
                                                }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 16, marginTop: -12, marginBottom: 24 }}>
                                                    {/* <img alt='' src={settingIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} /> */}
                                                    {/* <div style={{ display: 'flex', flexDirection: 'column',  width: "100%", alignItems: 'center', justifyContent: 'center', borderRadius: 16 ,height:16}}>

                                                    </div> */}
                                                    <Typography className={classes.popTitle}>
                                                        {t('footer_col1Sub2')}

                                                    </Typography>
                                                </MenuItem>



                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </div>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 84 }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 40, marginBottom: 40 }}>
                        <div style={{ width: '15%', alignSelf: 'center', marginLeft: 40 }}>
                            <Link to='/' onClick={closeMobileMenu}>
                                <img alt="" src={yesbitComLogo} style={{ width: 120 }} />
                            </Link>
                        </div>


                        <div style={{ width: '65%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Link style={{ textDecoration: 'none' }} to='/nft-homepage' onClick={closeMobileMenu}>
                                <Button className={classes.button} style={{ marginRight: 24 }} onClick={closeMobileMenu}>
                                    <Typography className={classes.fontStyle}>
                                        NFT
                                    </Typography>
                                </Button>
                            </Link>


                            {/* <Link style={{ textDecoration: 'none' }} to='/trade' onClick={closeMobileMenu}>
                                <Button className={classes.button} >
                                    <Typography className={classes.fontStyle}>
                                        Wealth
                                    </Typography>
                                </Button>
                            </Link> */}


                            {/*
                            <Button className={classes.button} onClick={closeMobileMenu}>


                                <a style={{ textDecoration: 'none' }} href={'https://wealth.yesbit.io'} className='nav-links' onClick={closeMobileMenu}>
                                    <Typography className={classes.fontStyle}>
                                        {t('navBar_Wealth')}
                                    </Typography>
                                </a>
                            </Button> */}

                            <Link style={{ textDecoration: 'none' }} to='/wealth' onClick={closeMobileMenu}>
                                <Button className={classes.button} >
                                    <Typography className={classes.fontStyle}>
                                        {t('navBar_Wealth')}
                                    </Typography>
                                </Button>
                            </Link>



                            <Link style={{ textDecoration: 'none' }} to='/referral-info' onClick={closeMobileMenu}>
                                <Button className={classes.button} >
                                    <img src={earnIcon} style={{ height: 20, width: 20, marginBottom: 2 }}></img>
                                    <Typography className={classes.fontStyle}>
                                        {t('navBar_Commission')}
                                    </Typography>
                                </Button>
                            </Link>

                            <Link style={{ textDecoration: 'none' }} onClick={() => window.open(localStorage.getItem('lng') === 'cn' ? "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/ " : "https://otcdocs.yesbit.io/")}>
                                <Button className={classes.button} onClick={closeMobileMenu}>
                                    <Typography className={classes.fontStyle}>
                                        {t('navBar_Tutorials')}
                                    </Typography>
                                </Button>
                            </Link>
                            {/*
                            <Button className={classes.button} onClick={() => {
                                window.scrollTo({
                                    top: document.documentElement.scrollHeight,
                                    behavior: 'smooth'

                                });
                            }} >
                                <Typography className={classes.fontStyle}>
                                    {t('navBar_Contact')}
                                </Typography>
                            </Button> */}


                            <Link style={{ textDecoration: 'none' }} to='/about-us' onClick={closeMobileMenu}>
                                <Button className={classes.button} >
                                    <Typography className={classes.fontStyle}>
                                        {t('aboutUs')}
                                    </Typography>
                                </Button>
                            </Link>




                            <Button className={classes.button} onClick={() => changeLanguage(isChinese)} >
                                <Typography className={classes.fontStyle}>
                                    {localStorage.getItem('lng') === 'cn' ? "English" : "中文"}
                                </Typography>
                            </Button>



                        </div>
                        <div style={{ width: '20%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <a
                                href={process.env.REACT_APP_ENV ==='prod' ? 'https://trade.yesbit.io/login' : '/login'}
                                style={{ textDecoration: 'none' }} onClick={closeMobileMenu}>

                                <Button onClick={() => {
                                    window.location.replace(process.env.REACT_APP_ENV ==='prod' ? 'https://trade.yesbit.io/login' : '/login')
                                }} className={classes.button} style={{ width: 80 }}>
                                    <Typography className={classes.fontStyle}>
                                        {t('footer_col1Sub2')}
                                    </Typography>
                                </Button>
                            </a>

                            <a href={process.env.REACT_APP_ENV ==='prod' ? 'https://trade.yesbit.io/register' : '/register'} style={{ textDecoration: 'none', width: 200 }} onClick={closeMobileMenu}>

                                <Button
                                    className={classes.button} style={{ border: "1.6px solid #3254FA", paddingLeft: 32, paddingRight: 32, borderRadius: 100 }} onClick={closeMobileMenu}>
                                    <Typography className={classes.fontStyle}>
                                        {t('footer_col1Sub3')}
                                    </Typography>
                                </Button>
                            </a>
                        </div>


                        {/* <div className='menu-icon' onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </div> */}
                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            {/*<li className='nav-item'>*/}
                            {/*    <Link to='/' className='nav-links' onClick={closeMobileMenu}>*/}
                            {/*        {t('home')}*/}
                            {/*    </Link>*/}

                            {/*</li>*/}

                            {/*<li className='nav-item'>*/}
                            {/*    <Link to='/nft-homepage' className='nav-links' onClick={closeMobileMenu}>*/}
                            {/*        {t('nft')}*/}
                            {/*    </Link>*/}

                            {/*</li>*/}

{/*
                            <li className='nav-item' style={{ width: 120 }}>
                                <a href={'https://wealth.yesbit.io'} className='nav-links' onClick={closeMobileMenu}>
                                    {t('quote')}
                                </a>

                            </li> */}

                            {/*<li className='nav-item'>*/}

                            {/*    <Link to='/referral-info' className='nav-links' onClick={closeMobileMenu}>*/}
                            {/*        {t('navReferral')}*/}
                            {/*    </Link>*/}


                            {/*</li>*/}


                            {/*<li className='nav-item'>*/}
                            {/*    <Link className='nav-links' onClick={() => changeLanguage(isChinese)}>*/}
                            {/*        {localStorage.getItem('lng') === 'cn' ? "English" : "中文"}*/}
                            {/*    </Link>*/}

                            {/*</li>*/}

                            {/*<li className='nav-btn'>*/}
                            {/*    {*/}
                            {/*        button ?*/}
                            {/*            (*/}
                            {/*                <Link to='/login' className='btn-link' style={{ margin: 'auto', paddingLeft: 0 }} onClick={closeMobileMenu}>*/}
                            {/*                    <Button*/}
                            {/*                        buttonStyle="btn--outline"*/}
                            {/*                        buttonSize='btn--medium'*/}
                            {/*                        buttonColor='blue'*/}
                            {/*                    >*/}


                            {/*                        {t('loginAndRegister')}*/}
                            {/*                    </Button>*/}
                            {/*                </Link>*/}
                            {/*            )*/}
                            {/*            :*/}
                            {/*            (*/}
                            {/*                <Link to='/login' className='btn-link' style={{ margin: 'auto', paddingLeft: 0 }} onClick={closeMobileMenu}>*/}
                            {/*                    <Button*/}
                            {/*                        buttonStyle='btn--primary'*/}
                            {/*                        buttonSize='btn--large'*/}
                            {/*                        buttonColor='blue'*/}
                            {/*                    >*/}
                            {/*                        {t('loginAndRegister')}*/}
                            {/*                    </Button>*/}
                            {/*                </Link>*/}
                            {/*            )*/}
                            {/*    }*/}

                            {/*</li>*/}


                        </ul>


                    </div>
                </div>
            }





        </>
    )
}

export default withTranslation()(Navbar)
