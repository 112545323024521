import unknowCoinIcon from '../images/unknowCoinIcon.png';
import USDT from '../images/coins/usdt.png'
import ETH from '../images/coins/eth.png'
import BTC from '../images/coins/btc.png'
import CAD from '../images/coins/cad.png'
import USD from '../images/funds/usd.png'
import 'intl';
import 'intl/locale-data/jsonp/en';
import bigDecimal from 'js-big-decimal';

export function getIcons(name) {
    let map = {
        'USDT': USDT,
        'ETH': ETH,
        'BTC': BTC,
        'CAD': CAD,
        'USD': USD
    }
    let url = map[name]
    return url !== undefined && url !== '' ? url : unknowCoinIcon
}

export const FormatNumber = (number) => {
    if (!isNaN(number)) {
        let round = parseFloat(roundingDown(number, 2))
        return Intl.NumberFormat().format(round)
    }
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const roundingDown = (number, places) => {
    if (number === null) {
        number = 0
    }
    if (!isNaN(number)) {
        if (Math.abs(number) < 0.000001 && number > 0) {
            let bd = new bigDecimal(number)
            return bd.round(places, bigDecimal.RoundingModes.DOWN).value
        }
        let numString = number.toString();
        let strLen = numString.length;
        let dotIndex = numString.indexOf('.');

        if (dotIndex === -1) {
            return numString;
        } else {
            if (dotIndex + places + 1 > strLen) {
                return numString;
            } else {
                return numString.substring(0, dotIndex + places + 1);
            }
        }
    } else {
        // console.log('roundingDown failed ! Invalid number: ', number, places)
    }
};

export const formDateString = (timeStamp) => {
    let date = new Date(timeStamp);
    let dateString = date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate() + ' ' +
        ('0' + date.getUTCHours()).slice(-2) + ':' + ('0' + date.getUTCMinutes()).slice(-2);
    return dateString
}

export const convertTimeString = ( timeString ) => {
    let availableTime = new Date((Date.parse(timeString)));
    let minute = availableTime.getUTCMinutes() >= 10 ? availableTime.getUTCMinutes() : '0' + availableTime.getUTCMinutes().toString()
    return availableTime.getUTCFullYear() + '-' + (availableTime.getUTCMonth() + 1) + '-' + availableTime.getUTCDate() + ' ' +
        ('0' + availableTime.getUTCHours()).slice(-2) + ':' + minute;
}


export const convertDateString = ( timeString ) => {
    let availableTime = new Date((Date.parse(timeString)));
    let minute = availableTime.getUTCMinutes() >= 10 ? availableTime.getUTCMinutes() : '0' + availableTime.getUTCMinutes().toString()
    return availableTime.getUTCFullYear() + '-' + (availableTime.getUTCMonth() + 1) + '-' + availableTime.getUTCDate()
}
export const isValidAddress = (address, regMap) => {
    if (address !== '' && address !== null) {
        let regex = new RegExp(regMap)
        return regex.test(address)
    } else {
        return false
    }
}

export const getScan = (chain, isTest) => {
    console.log('chain: ', chain, isTest)
    if (chain === 'ETH' && isTest === false) {
        return 'https://etherscan.io/tx/'
    }
    else if (chain === 'HECO' && isTest === false) {
        return 'https://hecoinfo.com/tx/'
    }
    else if (chain === 'BSC' && isTest === false) {
        return 'https://bscscan.com/tx/'
    }
    else if (chain === 'HSC' && isTest === false) {
        return 'https://hooscan.com/tx/'
    }
    else if (chain === 'TRX' && isTest === false) {
        return 'https://tronscan.org/#/transaction/'
    }
    else if (chain === 'BTC' && isTest === false) {
        return 'https://btc.com/btc/transaction/'
    }
    else if (chain === 'OMNI' && isTest === false) {
        return 'https://omniexplorer.info/tx/'
    }
    else if (chain === 'ETH' && isTest === true) {
        return 'https://ropsten.etherscan.io/tx/'
    }
    else if (chain === 'HECO' && isTest === true) {
        return 'https://testnet.bscscan.com/tx/'
    }
    else if (chain === 'BSC' && isTest === true) {
        return 'https://testnet.bscscan.com/tx/'
    }
    else if (chain === 'HSC' && isTest === true) {
        return 'https://testnet.hscscan.com/txn?hash='
    }
    else if (chain === 'BTC' && isTest === true) {
        return 'https://www.blockchain.com/btc-testnet/tx/'
    }
    else if (chain === 'OMNI' && isTest === true) {
        return 'https://blockexplorer.one/omni-layer/testnet/tx/'
    }
    else {
        return 'https://otc.yesbit.io'
    }
}

