import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {withTranslation} from 'react-i18next';

import './HeroSection.css'
import {Button, makeStyles, Typography} from '@material-ui/core';
import homeBannerEN from "../../images/home/homeBannerEN.png";
import homeBannerCN from "../../images/home/homeBannerCN.png";
import {useDispatch, useSelector} from "react-redux";

import Carouse from '../../components/Carousel'
import i18n from "../../i18n";
import QuickTradeModalFullSize from "../QuickTrade/components/QuickTradeModalFullSize";
import promotionEN from '../../images/trade/promotion_en.png'
import promotionCN from '../../images/trade/promotion_cn.png'

function HeroSection({ t, width, height }) {
    const [phone, setPhone] = useState(width <= 745);
    const [payValue, setPayValue] = useState('');
    const [receiveValue, setReceiveValue] = useState('');
    const [coin1Selected, setCoin1Selected] = useState('CAD')
    const [coin2Selected, setCoin2Selected] = useState('USDT')
    const [selected, setSelected] = useState('buy');
    const { ethPriceChange, btcPriceChange, usdtPriceChange, tradeBannerInfo } = useSelector(state => state.trade)

    const history = useHistory();
    const dispatch = useDispatch();
    const useStyles = makeStyles({
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',


            letterSpacing: 0,
            textAlign: phone ? 'center' : 'left',
        },

        fontStyle: {
            fontFamily: "Avenir", fontWeight: '500'
        },
        tradeContainer: {
            boxShadow: phone ? "unset" : "0px 8px 24px #F2F2F2"

        }
    })
    const classes = useStyles();



    useEffect(() => {
        setPhone(width <= 745)


    }, [width]);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {phone ?
                <div style={{ height: 1, width: '95%', backgroundColor: '#F2F2F2', marginBottom: 20 }}></div>

                :
                null
            }

            {phone ?
                <div style={{ display: phone ? 'block' : 'flex', width: '95%' }}>
                    {phone ? null : <div
                        style={
                            { width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundColor: 'orange' }
                        }
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <img
                                alt=''
                                src={i18n.language === 'cn' ? homeBannerCN : homeBannerEN}
                                style={
                                    { width: "100%" }
                                }
                            />
                            <Typography className={classes.font} style={{ fontWeight: 500, color: '#8B96A7', fontSize: 12, marginTop: 12, textAlign: 'left' }}>
                                {t('home_SubTitle')}
                            </Typography>
                            <div style={{ display: 'flex', marginTop: 9 }}>
                                <Button
                                    className={classes.font}
                                    ariant="text"
                                    style={{ padding: '0px', backgroundColor: '#3254FA', height: 40, width: 120, alignItems: 'center', justifyContent: 'ceter', borderRadius: 12, textTransform: 'none' }}
                                    onClick={() => { history.push('/login') }}

                                >
                                    <Typography className={classes.font} style={{ fontWeight: 500, color: 'white', fontSize: 12 }}>
                                        {t('section1_ButtonText')}
                                    </Typography>
                                </Button>

                                {/* <Button
                                    className={classes.font}
                                    ariant="text"
                                    style={{ width: 'auto', padding: '0px', alignItems: 'center', justifyContent: 'ceter', textTransform: 'none', marginLeft: 16 }}
                                    onClick={() => { history.push('/login') }}

                                >
                                    <img style={{ height: 12, width: 12 }} src={downloadAppIcon}></img>
                                    <Typography className={classes.font} style={{ fontWeight: 500, color: 'black', fontSize: 12 }}>
                                        {t('home_downloadApp')}
                                    </Typography>
                                </Button> */}

                            </div>


                        </div>

                    </div>}
                    <div style={{ width: '100%', height: 240, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 0 }}>


                        <div style={{ width: 426 * 0.86 }}>

                            <Carouse
                                transitionDuration={10}
                            >
                                {
                                    tradeBannerInfo.length > 0 && tradeBannerInfo != [] ?
                                        tradeBannerInfo.map(item => (
                                            <div style={{ borderRadius: 20, height: 210 * 0.86, width: 426 * 0.86, overflow: 'hidden' }} onClick={() => window.open(i18n.language === 'cn' ? item.cnBannerLink === '' || item.cnBannerLink === null ? '' : item.cnBannerLink : item.cnBannerLink === '' || item.cnBannerLink === null ? '' : item.engBannerLink, "_blank")}>
                                                <img style={{ objectFit: 'cover', borderRadius: 20, cursor: 'pointer', height: 210 * 0.86, width: 426 * 0.86 }} src={i18n.language === 'en' ? item.engBannerUri : item.cnBannerUri} alt="new" />
                                            </div>

                                        ))
                                        :
                                        <img src={i18n.language === 'cn' ?
                                            promotionCN : promotionEN}
                                            style={{ cursor: 'pointer', width: "100%", height: 201, boxShadow: 'unset', borderRadius: 20 }}
                                            onClick={() => {
                                                window.open(i18n.language === 'cn' ? 'https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/' : 'https://otcdocs.yesbit.io/')
                                            }}
                                        >
                                        </img>
                                }
                            </Carouse>
                        </div>


                    </div>
                </div>
                :
                <div style={{ width: '100%', backgroundColor: '#f9f9f9', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: phone ? 'block' : 'flex', width: '81%', alignSelf: 'center', marginTop: '2%', marginBottom: '4%' }}>
                        <div
                            style={
                                { width: '50%', display: 'flex', height: 400, backgroundSize: '100% 100%', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', position: 'relative' }
                            }
                        >
                            <div style={phone ? {} : { display: 'flex', flexDirection: 'column' }}>
                                <img
                                    alt=''
                                    src={i18n.language === 'cn' ? homeBannerCN : homeBannerEN}
                                    style={
                                        { width: 426 * 1.3 }
                                    }
                                />
                                <Typography className={classes.font} style={{ fontWeight: 500, color: '#8B96A7', fontSize: 16, marginTop: 24, marginBottom: 24 }}>
                                    {t('home_SubTitle')}
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: phone ? 'center' : null }}>
                                    <Button
                                        className={classes.font}
                                        ariant="text"
                                        style={{ padding: '0px', backgroundColor: '#3254FA', height: 60, width: 210, alignItems: 'center', justifyContent: 'ceter', borderRadius: 16, textTransform: 'none' }}
                                        onClick={() => { history.push('/register') }}

                                    >
                                        <Typography className={classes.font} style={{ fontWeight: 500, color: 'white', fontSize: 20 }}>
                                            {t('section1_ButtonText')}
                                        </Typography>
                                    </Button>

                                    {/* <Button
                                    className={classes.font}
                                    ariant="text"
                                    style={{ width: 'auto', padding: '0px', alignItems: 'center', justifyContent: 'ceter', textTransform: 'none', marginLeft: 16 }}
                                    onClick={() => { history.push('/login') }}

                                >
                                    <img style={{ height: 24, width: 24 }} src={downloadAppIcon}></img>
                                    <Typography className={classes.font} style={{ fontWeight: 500, color: 'black', fontSize: 20 }}>
                                        {t('home_downloadApp')}
                                    </Typography>
                                </Button> */}

                                </div>


                            </div>

                        </div>
                        <div style={{ width: phone ? '100%' : 800, height: 420, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>


                            <div style={{ height: width < 1500 ? 210 * 1.1 : 210 * 1.3, width: width < 1500 ? 425 * 1.1 : 425 * 1.3 }}>

                                <Carouse
                                    transitionDuration={10}

                                >

                                    {
                                        tradeBannerInfo.length > 0 && tradeBannerInfo != [] ?
                                            tradeBannerInfo.map(item => (
                                                <div style={{ borderRadius: 20, overflow: 'hidden' }} onClick={() => window.open(i18n.language === 'cn' ? item.cnBannerLink === '' || item.cnBannerLink === null ? '' : item.cnBannerLink : item.cnBannerLink === '' || item.cnBannerLink === null ? '' : item.engBannerLink, "_blank")}>
                                                    <img style={{ objectFit: 'cover', borderRadius: 20, cursor: 'pointer', height: width < 1500 ? 210 * 1.1 : 210 * 1.3, width: width < 1500 ? 425 * 1.1 : 425 * 1.3, boxShadow: 'unset' }} src={i18n.language === 'en' ? item.engBannerUri : item.cnBannerUri} alt="new" />
                                                </div>

                                            ))
                                            :
                                            <img src={i18n.language === 'cn' ?
                                                promotionCN : promotionEN}
                                                style={{ cursor: 'pointer', height: width < 1500 ? 210 * 1.1 : 210 * 1.3, width: width < 1500 ? 425 * 1.1 : 425 * 1.3, boxShadow: 'unset', borderRadius: 20 }}
                                                onClick={() => {
                                                    window.open(i18n.language === 'cn' ? 'https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/' : 'https://otcdocs.yesbit.io/')
                                                }}
                                            >
                                            </img>
                                    }


                                </Carouse>
                            </div>


                        </div>
                    </div>
                </div>
            }

            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <div style={{ width: '100%', height: 200, backgroundColor: phone ? "white" : '#f9f9f9', }}></div>
                <div style={{ width: phone ? "100%" : '81%', marginTop: -200, display: 'flex', alignItems: 'center', flexDirection: 'column', backgroundColor: phone ? "#f9f9f9" : 'white', borderRadius: phone ? "0" : 32 }} className={classes.tradeContainer}>
                    <div style={{ width: phone ? "91%" : '91%' }}>
                        <QuickTradeModalFullSize
                            phone={phone}
                            homePage={true}
                            next={() => { history.push('/trade') }}
                            payValue={payValue}
                            receiveValue={receiveValue}
                            setPayValue={setPayValue}
                            setReceiveValue={setReceiveValue}
                            coin1Selected={coin1Selected}
                            coin2Selected={coin2Selected}
                            setCoin1Selected={setCoin1Selected}
                            setCoin2Selected={setCoin2Selected}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(HeroSection)
