import React, { useEffect, useState } from 'react'
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/actions/authActions";
import {
    Avatar,
    Button,
    createTheme,
    InputAdornment,
    InputBase,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    MenuItem,
    Modal,
    MuiThemeProvider,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import passed from '../../images/passed.png';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { kycActions } from "../../redux/actions/kycActions";
import { formDateString, getIcons, isNumeric, roundingDown } from "../../utils/Common";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import eTransfer from '../../images/funds/eTransfer.png'
import cSupport from '../../images/funds/cSupport.png'
import { fundsActions } from "../../redux/actions/fundsActions";
import i18n from '../../i18n';
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import qrIcon from '../../images/qr.png'
import downArrow from '../../images/downArrow.png'
import tickIcon from "../../images/selectedIcon.png";
import cancel from "../../images/x.png";
import copy from 'copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';
import checkIcon from '../../images/checkIcon.png'
import './Fund.css'
import warningLabel from '../../images/warningLabel.png'
import enterIcon from '../../images/enterIcon.png'
import backToIcon from '../../images/backToIcon.png'
import fiadIcon from '../../images/fiadIcon.png'
import refreshIcon from '../../images/refreshIcon.png'
import closePopIcon from '../../images/closePopIcon.png'

import cryptoIcon from '../../images/cryptoIcon.png'
import dropdownIcon from '../../images/dropdownIcon.png'
import questionIcon from "../../images/questionIcon.png"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet";


function Fund({ t, width }) {
    const dispatch = useDispatch();
    const { token, userInfo, updateInfoSuccess } = useSelector(state => state.auth)
    const { kycDetails, fetched } = useSelector(state => state.kyc)
    const { tradeSubmitted, validDate, depositMsg, fundActionLoading, tokensInfo, cryptoAddress, gettingAddress } = useSelector(state => state.funds)
    const [phone, setPhone] = useState(width <= 745);
    const [loaded, setLoaded] = useState(false)

    const [inputting, setInputting] = useState(false)
    const [inputMsg, setInputMsg] = useState('')
    const [inputStatus, setInputStatus] = useState(0)
    const [confirming, setConfirming] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [canGoToConfirming, setCanGoToConfirming] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)

    const [depositingCrypto, setDepositingCrypto] = useState(false)

    const [depositAmount, setDepositAmount] = useState(0)
    const [currency, setCurrency] = useState('CAD')

    const [eTransferring, setETransferring] = useState(false)

    const [fundType, setFundType] = useState('Fiat')
    const [selectingFundType, setSelectingFundType] = useState(true)
    const [canGoToInput, setCanGoToInput] = useState(true)
    const [selectOpen, setSelectOpen] = useState(false)

    const [coin, setCoin] = useState('USDT')
    const [chain, setChain] = useState('TRX')
    const [address, setAddress] = useState('')

    const [showCryptoModal, setShowCryptoModal] = useState(false)
    const [showNetworkModal, setShowNetworkModal] = useState(false)
    const [showQrModal, setShowQrModal] = useState(false)

    const [tokenList, setTokenList] = useState([])
    const [chainList, setChainList] = useState([])

    const [copied, setCopied] = useState(false)
    const [depositInfo, setDepositInfo] = useState([])

    const [localDepositMsg, setLocalDepositMsg] = useState('')

    const eTransferMax = 5000
    const cSupportMin = 5000.0000000001
    const eTransferMin = 1000
    const cSupportMax = 1000000000000

    const handleSelectChange = (e) => {
        setCurrency(e.target.value);
    }

    const handleFundTypeChange = (e) => {
        if (e.target.value !== undefined && e.target.value !== '') {
            setCanGoToInput(true)
            setFundType(e.target.value);
        } else {
            setCanGoToInput(false)
        }

    }

    const handleCoinChange = (item) => {
        if (item !== undefined && item !== '') {
            setCoin(item);
        } else {
        }
    }

    const handleChainChange = (chain) => {
        if (chain !== undefined && chain !== '') {
            setChain(chain);
        } else {
        }
    }

    const handleClose = () => {
        setShowNetworkModal(false)
        setShowCryptoModal(false)
        setShowQrModal(false)
    }

    const useStyles = makeStyles({
        p: {
            fontSize: phone ? 12 : 16, fontFamily: 'Avenir',
        },
        em: {
            fontSize: phone ? 12 : 16, fontFamily: 'Avenir',
        },
        Typography: {
            fontSize: phone ? 12 : 16, fontFamily: 'Avenir',
        },
        inputLabel: {
            marginBottom: 12, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 14, letterSpacing: 0, lineHeight: '22px', fontWeight: '500', color: '#858E9D'

        },
        inputLabelPhone: {
            marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px', color: '#858E9D'
        },
        input: {
            borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: '35px 15px', fontWeight: '500', border: "1px solid #EDEDED", fontFamily: 'Avenir',
        },
        inputPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '100%', height: 55, fontSize: 14, padding: 14, border: "1px solid #EDEDED", fontFamily: 'Avenir',
        },
        button: {
            width: '100%',
            height: '65px',
            borderRadius: 20,
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
        },
        buttonPhone: {
            width: '100%',
            height: '48.3px',
            borderRadius: 12,
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
        },
        next: {
            color: 'white !important',
            backgroundColor: '#162E83',
            "&:hover": {
                backgroundColor: '#081647',
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#162E83'
                    }
                },
                '&:inactive': {
                    backgroundColor: '#162E83',
                },
            }
        },
        nextPhone: {
            color: 'white !important',
            backgroundColor: '#162E83',
            "&:hover": {
                backgroundColor: '#081647',
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#162E83'
                    }
                },
                '&:inactive': {
                    backgroundColor: '#162E83',
                },
            }
        },
        back: {
            color: '1C2237 !important',
            backgroundColor: 'transparent',
            transition: 'transform .2s',
            "&:hover": {
                transform: 'scale(1.5)',
                backgroundColor: 'transparent'
            }
        },
        backPhone: {
            color: '1C2237 !important',
            backgroundColor: 'transparent',
            transition: 'transform .2s',
            "&:hover": {
                transform: 'scale(1.5)',
                backgroundColor: 'transparent'
            }
        },
        fundTypeSelect: {
            borderRadius: phone ? 12 : 16,
            backgroundColor: '#F1F0F0',
            width: '100%',
            height: phone ? '50px' : '55px',
            fontSize: 22,
            fontFamily: 'Avenir',
            padding: phone ? 15 : '35px 15px',
            fontWeight: '500',
            color: 'white',
            borderBottom: null,
            '&:before': {
                borderColor: 'transparent',
            },
            '&:after': {
                borderColor: 'transparent',
            },
            '& .MuiSelect-selectMenu': {
                display: 'flex',
                fontWeight: '500',
                justifyContent: 'flex-start',
                alignItems: 'center'
            },
            '& .MuiSelect-select': {
                fontSize: 16
            },
            '& .MuiSelect-selected': {
                backgroundColor: 'transparent'
            },
        },
        cryptoSelect: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderRadius: phone ? 12 : 16,
            backgroundColor: 'white',
            width: 'auto',
            height: phone ? 'unset' : '55px',
            fontSize: 22,
            fontFamily: 'Avenir',
            padding: phone ? '8px 10px' : '10px 15px',
            fontWeight: '500',
            color: 'white',
            borderBottom: null,
            border: "1px solid #EDEDED"
        },
        select: {
            // backgroundColor: '#162E83',
            // borderRadius: 10,
            // color: 'white',
            fontFamily: 'Avenir',
            fontSize: phone ? 12 : 16,
            padding: phone ? 0 : 8,
            borderBottom: null,
            width: phone ? 100 : 120,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:before': {
                borderColor: 'transparent',
            },
            '&:after': {
                borderColor: 'transparent',
            },
            '& .MuiSelect-selectMenu': {
                display: 'flex',
                fontWeight: '500',
                justifyContent: 'center',
                alignItems: 'center'
            },
            '& .MuiSelect-select': {
                fontSize: 12
            }
        },
        selectedPay: {
            backgroundColor: '#0173F9',
            marginBottom: 15, borderRadius: 12, padding: '15px 15px',
            '& span, p': {
                color: 'white'
            },
            '& ListItemText': {
                color: 'white'
            }
        },
        nonSelectedPay: {
            border: '1px solid #858E9D',
            marginBottom: 15, borderRadius: 12, padding: '15px 15px'
        },
        listItem: {
            color: '#1C2237',
            "& span": {
                fontWeight: '500',
            }
        },
        confirming: {
            borderRadius: 16, border: 'solid thin #9097A1', backgroundColor: 'transparent', width: '100%', height: '55px', fontSize: 22, padding: 15, fontFamily: 'Avenir',
        },
        confirmingPhone: {
            borderRadius: 12, border: 'solid thin #9097A1', backgroundColor: 'transparent', width: '100%', height: '40px', fontSize: 10, padding: 14, fontFamily: 'Avenir',
        },
        email: {
            borderRadius: 16, backgroundColor: '#F1F0F0', width: '100%', fontSize: 22, fontWeight: '500', height: '55px', justifyContent: 'center', padding: '0px 15px', fontFamily: 'Avenir',
        },
        emailPhone: {
            borderRadius: 12, backgroundColor: '#F1F0F0', width: '100%', fontSize: 10, height: '40px', justifyContent: 'center', padding: '0px 14px', fontFamily: 'Avenir',
        },
        confirmList: {
            padding: 0,
            marginTop: 12,
        },
        confirmListTitle: {
            color: '#858E9D',
            fontSize: phone ? 12 : 14,
            fontWeight: '500', fontFamily: 'Avenir',
        },
        confirmListText: {
            fontSize: phone ? 12 : 16,
            color: 'black',
            fontWeight: '500', fontFamily: 'Avenir',
        },
        menu: {
            '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
                backgroundColor: 'transparent'
            }
        },
        addressButton: {
            borderRadius: phone ? 16 : 20,

            width: 'auto',
            fontSize: 22,
            padding: '15px 10px',
            fontWeight: '500',
            borderBottom: null,
            backgroundColor: '#f1f0f0', fontFamily: 'Avenir',
        },
        depositNote: {
            height: 38,
            overflow: 'auto',
            listStyle: 'none',
            display: 'contents'
        },
        depositNoteText: {
            color: '#0173F9',
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 500, fontFamily: 'Avenir',
        },
        copyIcon: {
            "&:hover": {
                fill: '#010846'
            }
        },
        submittedTitleText: {
            fontSize: phone ? 12 : 14,
            marginTop: phone ? 8 : 12,
            color: "#858E9D",
            fontWeight: '500',
            textAlign: 'left', fontFamily: 'Avenir',

        },
    });
    const classes = useStyles();
    const history = useHistory();

    const defaultTheme = createTheme();
    const theme1 = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    height: 43,
                    width: 128,
                    borderRadius: 5,
                    backgroundColor: "#010846",
                    color: 'white',
                    fontSize: 14,
                    fontWeight: 500,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', fontFamily: 'Avenir',
                }
            }
        }
    });

    const theme2 = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    height: 218,
                    width: 211,
                    borderRadius: 13,
                    backgroundColor: "#FFFFFF",
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0 0 29px 0 rgba(0,0,0,0.11)'
                }
            }
        }
    });

    useEffect(() => {
        setPhone(width <= 745)
        return () => {
        }
    }, [width]);

    useEffect(() => {
        dispatch(fundsActions.getAddressByChain(token, { chain: chain }))
        dispatch(authActions.getUserInfo(token))
        dispatch(kycActions.syncKycStatus(token))
        dispatch(kycActions.syncAddressStatus(token))
        dispatch(fundsActions.getTokenInfo(token))
        setLoaded(true)
        return () => {
            setConfirming(false)
            setInputting(false)
            setSubmitted(false)
            setDepositingCrypto(false)
            dispatch(fundsActions.clear())
        }
    }, []);

    useEffect(() => {
        if (fetched) {
            dispatch(kycActions.getKycDetails(token))
        }
    }, [fetched])

    useEffect(() => {
        checkInput()
    }, [currency, depositAmount])

    useEffect(() => {
        if (depositAmount < cSupportMin && depositAmount >= eTransferMin) {
            setETransferring(true)
            setInputStatus(1)
            setInputMsg('')
        } else {
            if (depositAmount === 0) {
                setInputStatus(0)
            }
            if (depositAmount < cSupportMin & depositAmount !== 0) {
                setInputMsg('lessThanMin')
                setInputStatus(2)
            } else {
                setInputMsg('')
            }
            setETransferring(false)
        }
    }, [depositAmount])

    useEffect(() => {
    }, [inputStatus])

    useEffect(() => {
        if (depositMsg === 'Unable to send email money request to user.') {
            setLocalDepositMsg(t('serverUnavailable'))
        } else {
            setLocalDepositMsg(depositMsg)
        }
        if (tradeSubmitted) {
            setConfirming(false)
            setInputting(false)
            setSubmitted(true)
            if (validDate.redirectUrl) {
                window.open(validDate.redirectUrl, '_blank')
                history.push('/history', { tab: 1 })
                dispatch(fundsActions.clear())
            }
        }
        if (depositMsg !== '' && !tradeSubmitted) {
            setSubmitted(false)
        }
    }, [depositMsg, tradeSubmitted])

    useEffect(() => {
        // console.log('tokensInfo: ', tokensInfo)
        // console.log('cryptoAddress: ', cryptoAddress)
        setAddress(cryptoAddress.address)
        let tempList = []
        for (let i = 0; i < tokensInfo.length; i++) {
            let existed = tempList.find(item => item.token === tokensInfo[i].token)
            if (existed === undefined) {
                let open = tokensInfo.filter(item => item.token === tokensInfo[i].token && item.depositIsOn)
                tempList.push({
                    token: tokensInfo[i].token,
                    open: open
                })
            }
            getDepositInfo()
        }
        setTokenList(tempList)
    }, [tokensInfo, cryptoAddress])

    useEffect(() => {
        setCopied(false)
        dispatch(fundsActions.getAddressByChain(token, { chain: chain }))
    }, [chain])

    useEffect(() => {
        getAvailableChain()
        getDepositInfo()
    }, [coin, tokensInfo])

    useEffect(() => {
        // console.log('address: ', address)
    }, [address, gettingAddress])

    const getAvailableChain = () => {
        let chainList = tokensInfo.filter(item => item.token === coin && item.depositIsOn === true)
        console.log('chainList: ', chainList)
        setChainList(chainList)
        if (chainList !== undefined && chainList.length > 0) {
            setChain(chainList[0].chain)
        }

        /** hard code default chain for USDT to be TRX **/
        if (coin === 'USDT') {
            setChain('TRX')
        }
    }

    const getDepositInfo = () => {
        let tempDepositInfo = tokensInfo.filter(item => item.token === coin)
        if (tempDepositInfo !== undefined) {
            setDepositInfo(tempDepositInfo)
        }
    }

    const checkInput = () => {
        if (depositAmount >= eTransferMin && currency !== '') {
            setCanGoToConfirming(true)
        } else {
            setCanGoToConfirming(false)
        }
    }

    const handleDepositAmountChange = (event) => {
        if (isNumeric(event.target.value)) {
            setDepositAmount(event.target.value);
        } else {
            setDepositAmount(0)
        }
    };

    const handleDepositAmountFocus = (event) => {
        if (event.target.value === '') {
            setDepositAmount(0)
        } else {
            setDepositAmount(parseFloat(event.target.value))
        }
    }

    const handleDepositAmountBlur = (event) => {
        if (event.target.value === '') {
            setDepositAmount(0)
        } else {
            setDepositAmount(parseFloat(roundingDown(event.target.value, 2)))
        }
    }

    const goToInput = () => {
        setSelectingFundType(false)
        setDepositingCrypto(false)
        setConfirming(false)
        setInputting(true)
        setSubmitted(false)
    }

    const goToDepositCrypto = () => {
        setSelectingFundType(false)
        setDepositingCrypto(true)
        setConfirming(false)
        setInputting(false)
        setSubmitted(false)
    }

    const goBack = () => {
        dispatch(fundsActions.clear())
        history.goBack()
    }

    const goBackSelectFundType = () => {
        setConfirming(false)
        setInputting(false)
        setSubmitted(false)
        setSelectingFundType(true)
        setDepositingCrypto(false)
        dispatch(fundsActions.clear())
    }

    const goBackFromSubmitted = () => {
        setConfirming(false)
        setInputting(true)
        setSubmitted(false)
        setDepositingCrypto(false)
        dispatch(fundsActions.clear())
        history.goBack()
    }

    const goToConfirming = () => {
        setDepositingCrypto(false)
        setConfirming(true)
        setInputting(false)
        setSubmitted(false)
    }

    const submit = () => {
        if (typeof depositAmount === 'string') {
            setDepositAmount(parseFloat(roundingDown(depositAmount, 2)))
        }
        let payload = {
            "symbol": currency,
            "amount": depositAmount,
            "paymentMethod": eTransferring ? "InteracETransfer" : "CustomerSupport"
        }

        // console.log('submitting: ', payload)
        dispatch(fundsActions.fiatDeposit(payload))
    }

    const backToInput = () => {
        setDepositingCrypto(false)
        setConfirming(false)
        setInputting(true)
        setSubmitted(false)
        dispatch(fundsActions.clear())
    }

    const paymentMethod = [
        {
            title: t('InteracETransfer'),
            subTitle: t('eTransferSub'),
            icon: eTransfer,
            range: `$${eTransferMin}-$${eTransferMax}`,
            max: eTransferMax,
            min: eTransferMin,
        },
        {
            title: t('CustomerSupportTitle'),
            subTitle: t('cSupportSub'),
            icon: cSupport,
            range: '$5000+',
            max: cSupportMax,
            min: cSupportMin
        },
    ]

    const onSelectOpen = () => {
        setSelectOpen(true)
    }

    const onSelectClose = () => {
        setSelectOpen(false)
    }

    const handleSelectIcon = () => {
        if (selectOpen) {
            return <ArrowDropUp />
        } else {
            return <ArrowDropDown />
        }
    }

    const openCryptoModal = () => {
        setShowCryptoModal(true)
    }

    const openNetworkModal = () => {
        setShowNetworkModal(true)
    }

    const openQrModal = () => {
        setShowQrModal(true)
    }

    const getAddress = () => {
        dispatch(fundsActions.getAddressByChain(token, { chain: chain }))
    }

    const copyAddress = () => {
        copy(cryptoAddress.address)
        setCopied(true)
    }

    const renderSelectFundType = () => {
        return (
            <div style={{}}>
                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            style={
                                phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                    : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                            }
                        >
                            {t("fundingTitle")}
                        </Typography>
                        <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 14, fontWeight: '500', fontFamily: 'Avenir', }} >
                            {t("depositCoinSub")}
                        </Typography>
                    </div>
                    <Button

                        style={{ padding: 4 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { goBack() }}
                    >
                        {/* <Typography style={{ fontSize: phone ? 10 : 14, fontWeight: '500' }}>
                            {t('back')}
                        </Typography> */}
                        <img src={backToIcon} style={{ width: phone ? 24 : 32, marginRight: 4 }} />


                    </Button>
                </div>

                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 36 }} />
                <Button style={{ borderRadius: 20, backgroundColor: '#FBFBFB', display: 'flex', alignItems: 'center', height: phone ? 80 : 100, overflow: 'hidden', width: '100%', padding: 0  }} onClick={() => { goToInput() }}>
                    <div style={{ display: 'flex', width: '25%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#06746B', height: phone ? 80 : 100 }}>
                        <img src={fiadIcon} style={{ width: phone ? 24 : 32 }} />
                    </div>

                    <div style={{ width: '75%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginLeft: 16 }}>
                            <Typography style={{ fontSize: phone ? 14 : 18, fontWeight: '500', textTransform: 'none', fontFamily: 'Avenir', }}>{t('cad')}</Typography>
                            <Avatar style={{ width: phone ? 14 : 20, height: phone ? 14 : 20, alignSelf: 'center', marginRight: 8, marginTop: 8 }} src={getIcons('CAD')} variant="square" />

                        </div>
                        <img src={enterIcon} style={{ width: phone ? 24 : 32, marginRight: 16 }} />

                    </div>
                </Button>
                <Button style={{ borderRadius: 20, backgroundColor: '#FBFBFB', display: 'flex', alignItems: 'center', height: phone ? 80 : 100, overflow: 'hidden', width: '100%', padding: 0, marginTop: 24}} onClick={() => { goToDepositCrypto() }}>
                    <div style={{ display: 'flex', width: '25%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFCC00', height: phone ? 80 : 100 }}>
                        <img src={cryptoIcon} style={{ width: phone ? 24 : 32 }} />

                    </div>

                    <div style={{ width: '75%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginLeft: 16 }}>
                            <Typography style={{ fontSize: phone ? 14 : 18, fontWeight: '500', textTransform: 'none', fontFamily: 'Avenir', }}>{t('crypto')}</Typography>
                            <div style={{ display: 'flex' }}>
                                {tokenList.map(item => (
                                    <Avatar style={{ width: phone ? 14 : 20, height: phone ? 14 : 20, alignSelf: 'center', marginRight: 8, marginTop: 8 }} src={getIcons(item.token)} variant="square" />
                                ))}
                            </div>
                        </div>
                        <img src={enterIcon} style={{ width: phone ? 24 : 32, marginRight: 16 }} />

                    </div>
                </Button>




                {/* <div style={{ marginBottom: 40, marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%' }}>


                        <Select
                            value={fundType}
                            onChange={(e) => handleFundTypeChange(e)}
                            style={{ color: 'black' }}
                            displayEmpty
                            disableUnderline
                            className={classes.fundTypeSelect}
                            inputProps={{
                                'aria-label': 'Without label', classes: {
                                    icon: classes.icon
                                }
                            }}
                            IconComponent={() => handleSelectIcon()}
                            MenuProps={{
                                classes: {
                                    paper: classes.menu
                                }
                            }}
                            onClose={onSelectClose}
                            onOpen={onSelectOpen}
                        >
                            <MenuItem value={'Fiat'}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: 600, padding: 10 }}>{t('fiat')}</Typography>
                                </div>
                            </MenuItem>
                            <MenuItem value={'Crypto'}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: 600, padding: 10 }}>{t('crypto')}</Typography>
                                </div>
                            </MenuItem>
                        </Select>
                    </div>
                </div> */}
                <div style={{ display: 'flex', flexDirection: 'column', height: 100 }}>
                    {/* <Button
                        className={phone ? `${classes.buttonPhone} ${classes.nextPhone}` : `${classes.button} ${classes.next}`}
                        style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded || !canGoToInput ? 0.3 : 1 }}
                        disabled={fundActionLoading || !loaded || !canGoToInput}
                        onClick={() => { fundType === 'Fiat' ? goToInput() : goToDepositCrypto() }}
                    >
                        {t('next')}
                    </Button> */}
                    {/* <Button
                        className={phone?`${classes.buttonPhone} ${classes.backPhone}`:`${classes.button} ${classes.back}`}
                        style={{margin:phone ? '8px 0':'15px 0', opacity: fundActionLoading || !loaded ? 0.3 : 1}}
                        disabled={fundActionLoading || !loaded}
                        onClick={()=> {goBack()}}
                    >
                        <ArrowBackIosIcon style={{fill: '#1C2237'}} />{t('back')}
                    </Button> */}
                </div>
            </div>
        )
    }

    const renderDepositCrypto = () => {
        return (
            <div>


                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography
                            style={
                                phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                    : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                            }
                        >
                            {i18n.language === 'en' ? `${t("deposit")} ${t('crypto')}` : `${t("deposit")}${t('crypto')}`}
                        </Typography>
                        {/* <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 14, fontWeight: '400' }} >
                            {i18n.language === 'en' ? `${t("deposit")} ${t('crypto')}` : `${t("deposit")}${t('crypto')}`}
                        </Typography> */}
                        <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 14, fontWeight: '400', textAlign: 'left', fontFamily: 'Avenir', }} >
                            {t('depositCoinSub1')}
                        </Typography>
                    </div>
                    <Button

                        style={{ padding: 4 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { goBackSelectFundType() }}
                    >

                        <img src={backToIcon} style={{ width: phone ? 24 : 32, marginRight: 4 }} />


                    </Button>
                </div>

                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 36 }} />
                <div style={{ marginBottom: 40, marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('coin')}
                        </Typography>
                        <div
                            style={{ cursor: 'pointer', color: 'black' }}
                            className={classes.cryptoSelect}
                            onClick={openCryptoModal}
                        >
                            <Avatar style={{ width: 22, height: 21, borderRadius: 5, alignSelf: 'center' }} src={getIcons(coin)} variant="square" />
                            <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: 500, padding: 10, fontFamily: 'Avenir', }}>{coin}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <Avatar style={{ width: 14, height: 7, borderRadius: 5, alignSelf: 'center' }} src={downArrow} variant="square" />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', marginTop: 40 }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('network')}
                        </Typography>
                        <div
                            style={{ cursor: 'pointer', color: 'black' }}
                            className={classes.cryptoSelect}
                            onClick={openNetworkModal}
                        >
                            <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: 500, padding: 10, fontFamily: 'Avenir', }}>{chain === 'TRX' ? 'TRC20' : chain}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <Avatar style={{ width: 14, height: 7, borderRadius: 5, alignSelf: 'center' }} src={downArrow} variant="square" />
                            </div>
                        </div>
                    </div>


                    <div style={{ width: '100%', marginTop: 50 }}>
                        <Typography className={phone ? classes.inputLabelPhone : classes.inputLabel} >{t('depositAddress')}</Typography>

                        {
                            address === '' ?
                                <Button disabled={gettingAddress || !loaded || fundActionLoading} onClick={getAddress} className={classes.addressButton} style={{ width: '100%' }}>
                                    {
                                        gettingAddress ?
                                            <div id="loading-bar-spinner" className="spinner">
                                                <div className="spinner-icon"></div>
                                            </div> : null
                                    }
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <Typography style={{ fontSize: phone ? 14 : 16, fontWeight: 500, padding: 10, color: '#0173F9', textTransform: 'none', fontFamily: 'Avenir', }}>{t('getAddress')}</Typography>
                                    </div>
                                </Button>
                                :
                                <div className={classes.addressButton}  >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',


                                    }}>
                                        {
                                            address === '' ?
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                }}>
                                                    <Typography style={{ fontSize: phone ? 12 : 14, fontWeight: 500, padding: '0 10px', color: '#9097A1' }}>{t('noValidAddress')}</Typography>
                                                </div>
                                                :
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                }}>
                                                    {/* <Typography style={{ fontSize: phone ? 12 : 14, fontWeight: 500, padding: '0 10px', color: '#9097A1' }}>{t('depositAddress')}</Typography> */}
                                                    <Typography style={{ textAlign: 'left', maxWidth: 250, wordBreak: 'break-word', fontSize: phone ? 12 : 16, fontWeight: 500, padding: '0 10px', fontFamily: 'Avenir', }}>{address}</Typography>
                                                </div>
                                        }
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                        }}>
                                            <MuiThemeProvider theme={theme1}>
                                                <Tooltip enterTouchDelay={phone ? 0 : 700} title={copied ?
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <Typography>{t('copied')}</Typography>
                                                        <CheckOutlinedIcon style={{ paddingLeft: 5, fontSize: 20, alignSelf: 'center', color: 'white', fontFamily: 'Avenir', }} />
                                                    </div> : t('cpTip')} placement="top">
                                                    <FileCopyOutlinedIcon onClick={copyAddress} className={classes.copyIcon} style={{ color: '#0173F9', cursor: 'pointer', padding: '0 10px', fontSize: 25, alignSelf: 'center', fontFamily: 'Avenir', }} />
                                                    {/*<Avatar className={classes.copyIcon}  onClick={copyAddress} style={{ cursor: 'pointer', padding: '0 10px', width: 21, height: 25, borderRadius: 5, alignSelf: 'center' }} src={copyIcon} variant="square" />*/}
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            <MuiThemeProvider theme={theme2}>
                                                {
                                                    phone ?
                                                        <Avatar onClick={openQrModal} style={{ cursor: 'pointer', padding: '0 10px', width: 30, height: 25, borderRadius: 5, alignSelf: 'center' }} src={qrIcon} variant="square" />
                                                        :
                                                        <Tooltip enterTouchDelay={phone ? 0 : 700} title={
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                                <QRCodeSVG value={address} size={156} />
                                                            </div>
                                                        }>
                                                            <Avatar onClick={openQrModal} style={{ cursor: 'pointer', padding: '0 10px', width: 30, height: 25, borderRadius: 5, alignSelf: 'center' }} src={qrIcon} variant="square" />
                                                        </Tooltip>
                                                }
                                            </MuiThemeProvider>
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            address === '' ?
                                null : <div style={{ marginTop: 10, marginBottom: 10 }}>
                                    <ul className={classes.depositNote}>
                                        <li className={classes.depositNoteText}>{`* ${t('depositNote1')} ${coin} ${t('depositNote1-1')}`}</li>
                                        <li className={classes.depositNoteText}>{`* ${t('depositNote2')} ${chain === 'TRX' ? 'TRC20' : chain} ${t('depositNote2-2')}`}</li>
                                    </ul>
                                </div>
                        }
                        {/* <Button
                            className={phone ? `${classes.buttonPhone} ${classes.backPhone}` : `${classes.button} ${classes.back}`}
                            style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded ? 0.3 : 1 }}
                            disabled={fundActionLoading || !loaded}
                            onClick={() => { goBackSelectFundType() }}
                        >
                            <ArrowBackIosIcon style={{ fill: '#1C2237' }} />{t('back')}
                        </Button> */}
                    </div>
                </div>

            </div >
        )
    }

    const renderInputting = () => {
        return (
            <div>
                {/* <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        style={
                            phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                        }
                    >
                        {i18n.language === 'en' ? `${t("fundingTitle")} ${t('fiat')}` : `${t("fundingTitle")}${t('fiat')}`}
                    </Typography>

                    <Button

                        style={{ opacity: fundActionLoading || !loaded ? 0.3 : 1, backgroundColor: '#F9F9F9', borderRadius: 8, textTransform: 'none' }}
                        disabled={fundActionLoading || !loaded}
                        // onClick={() => { goBack() }}

                        onClick={() => { goBackSelectFundType() }}

                    >
                        <Typography style={{ fontSize: phone ? 10 : 14, fontWeight: '500' }}>
                            {t('back')}
                        </Typography>

                    </Button>
                </div> */}

                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography
                            style={
                                phone ? { color: "#1C2237", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial', fontFamily: 'Avenir', }
                                    : { color: "#1C2237", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial', fontFamily: 'Avenir', }
                            }
                        >
                            {i18n.language === 'en' ? `${t("fundingTitle")} ${t('fiat')}` : `${t("fundingTitle")}${t('fiat')}`}
                        </Typography>
                        {/* <Typography style={{ color: '#858E9D', fontSize: 14, fontWeight: '400' }} >
                            {i18n.language === 'en' ? `${t("fundingTitle")} ${t('fiat')}` : `${t("fundingTitle")}${t('fiat')}`}
                        </Typography> */}
                        <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 14, fontWeight: '400', fontFamily: 'Avenir', }} >
                            {t('depositCoinSub2')}
                        </Typography>
                    </div>
                    <Button

                        style={{ padding: 4 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { goBackSelectFundType() }}
                    >

                        <img src={backToIcon} style={{ width: phone ? 24 : 32, marginRight: 4 }} />


                    </Button>
                </div>

                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 36 }} />
                <div style={{ marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('depositAmount')}
                        </Typography>
                        <InputBase
                            placeholder={"0.00"}
                            className={phone ? classes.inputPhone : classes.input}

                            value={depositAmount === 0 ? '' : depositAmount.toString()}
                            style={{ color: 'black' }}
                            onChange={handleDepositAmountChange}
                            onFocus={handleDepositAmountFocus}
                            onBlur={handleDepositAmountBlur}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Select
                                        value={currency}
                                        onChange={(e) => handleSelectChange(e)}
                                        displayEmpty
                                        disableUnderline
                                        className={classes.select}
                                        inputProps={{ 'aria-label': 'Without label', icon: classes.icon }}
                                        IconComponent={(props) =>
                                            <div style={{ marginLeft: -24, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRight: '1px solid #D8D8D8', height: 30 }}>
                                                <img style={{ width: 16, height: 16, marginRight: 12 }} src={dropdownIcon} />
                                            </div>} >
                                        <MenuItem value="" disabled>
                                            <em style={{ fontStyle: 'normal' }}>{t('currency')}</em>
                                        </MenuItem>
                                        <MenuItem value={'CAD'}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Avatar style={{ marginRight: 5, width: 18, height: 18, alignSelf: 'center', marginBottom: 2 }} src={getIcons('CAD')} variant="square" />
                                                <Typography style={{ fontSize: phone ? 12 : 16, color: '#1C2237', fontWeight: '500', fontFamily: 'Avenir', }}>CAD</Typography>
                                            </div>
                                        </MenuItem>
                                        {/*<MenuItem value={'USD'}>*/}
                                        {/*    <div style={{ display: 'flex', alignItems: 'center' }}>*/}
                                        {/*        <Avatar style={{ marginRight: 5, width: 24, height: 24, borderRadius: 5, alignSelf: 'center' }} src={getIcons('USD')} variant="square" />*/}
                                        {/*        <Typography style={{ fontSize: phone ? 12 : 16 }}>USD</Typography>*/}
                                        {/*    </div>*/}
                                        {/*</MenuItem>*/}
                                    </Select>
                                </InputAdornment>
                            }
                            disabled={fundActionLoading || !loaded}
                        />
                        {/* {
                                inputStatus === 0 ? null :
                                    <Typography
                                        style={
                                            phone ? {marginTop:10, color:"red", fontFamily:"Avenir", fontSize:12, fontWeight:500, textAlign:'initial'}
                                                : {marginTop:10, color:"red", fontFamily:"Avenir", fontSize:14, fontWeight:500, textAlign:'initial'}
                                        }
                                    >
                                        {t(inputMsg)}
                                    </Typography>
                            } */}

                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 8, height: 24, opacity: inputMsg === "" ? 0 : 1 }}>
                            <Typography style={{ color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: '500', fontFamily: 'Avenir', }}>

                                {t(inputMsg)}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ width: '100%', marginTop: 40 }}>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
                            <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir' }}>  {t('paymentMethod')}</Typography>
                            <Button style={{
                                display: 'flex', alignItems: 'center', flexDirection: 'column',
                                padding: 0,
                                minHeight: 0,
                                minWidth: 0,
                            }}
                                onClick={() => { window.open(i18n.language === 'en' ? "https://otcdocs.yesbit.io/user-guide/funding/funding-fiat-currency" : "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/user-guide/deposit/fiat") }}

                            >
                                <img src={questionIcon} style={{ height: phone ? 14 : 18, marginRight: 4 }} />
                                <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir' }}>{t('fundingHelpButton')}</Typography>

                            </Button>

                        </div>


                        <List style={{ width: '100%' }} >
                            {paymentMethod.map(item => (
                                <ListItem key={item.title} style={{
                                    backgroundColor: '#F1F0F0',
                                    marginBottom: 24, borderRadius: 12, padding: '15px 15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <Avatar style={{ width: 42, height: 42, borderRadius: 5, alignSelf: 'center' }} src={item.icon} variant="square" /> */}

                                        <img src={item.icon} style={{ width: phone ? 16 : 32, marginRight: 16 }} />

                                        <div style={{ marginRight: 5 }}>
                                            <Typography

                                                style={{ color: 'black', fontSize: phone ? 12 : 14, fontWeight: '500', fontFamily: 'Avenir', }}
                                            >
                                                {item.title}
                                            </Typography>

                                            <Typography style={{ color: '#858E9D', fontSize: phone ? 10 : 12, fontWeight: '500' }}>
                                                {item.subTitle}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                                        <Typography style={{ color: depositAmount >= item.min && depositAmount <= item.max ? "black" : '#858E9D', fontSize: phone ? 14 : 16, fontWeight: '500', fontFamily: 'Avenir', }}>
                                            {item.range}
                                        </Typography>
                                    </div>
                                    {
                                        depositAmount >= item.min && depositAmount <= item.max ?

                                            <div style={{ height: phone ? 18 : 24, borderRadius: 14, backgroundColor: '#0173F9', position: 'absolute', right: 12, bottom: -10, display: 'flex', alignItems: 'center' }}>


                                                <Avatar style={{ width: phone ? 12 : 16, height: phone ? 12 : 16, alignSelf: 'center', marginLeft: 4 }} src={checkIcon} variant="square" />
                                                <Typography style={{ color: 'white', fontSize: phone ? 10 : 12, fontWeight: '500', marginRight: 8, marginLeft: 4, fontFamily: 'Avenir', }}>

                                                    {t('selected')}
                                                </Typography>
                                            </div> : null

                                    }


                                </ListItem>
                            )
                            )}
                        </List>
                    </div>
                    {
                        depositMsg === '' ? null :
                            <Typography
                                style={
                                    phone ? { marginTop: 10, color: "red", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                                        : { marginTop: 10, color: "red", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                                }
                            >
                                {t(depositMsg)}
                            </Typography>
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: 40 }}></div>
                    <Button
                        className={phone ? `${classes.buttonPhone} ${classes.nextPhone}` : `${classes.button} ${classes.next}`}
                        style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded || !canGoToConfirming ? 0.3 : 1 }}
                        disabled={fundActionLoading || !loaded || !canGoToConfirming}
                        onClick={() => { eTransferring ? goToConfirming() : submit() }}
                    >
                        {t('next')}
                    </Button>
                    {/* <Button
                            className={phone?`${classes.buttonPhone} ${classes.backPhone}`:`${classes.button} ${classes.back}`}
                            style={{margin:phone ? '8px 0':'15px 0', opacity: fundActionLoading || !loaded ? 0.3 : 1}}
                            disabled={fundActionLoading || !loaded}
                            onClick={()=> {goBackSelectFundType()}}
                        >
                            <ArrowBackIosIcon style={{fill: '#1C2237'}} />{t('back')}
                        </Button> */}
                    <div style={{ height: 80 }}></div>
                </div>
            </div>
        )
    }

    const renderConfirming = () => {
        return (
            <div>
                {/* <Typography
                    style={
                        phone ? { marginTop: 40, color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                            : { marginTop: 60, color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                    }
                >
                    {t("confirmFund")}
                </Typography> */}

                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            style={
                                phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                    : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                            }
                        >
                            {t("confirmFund")}
                        </Typography>

                    </div>
                    <Button

                        style={{ padding: 4 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { backToInput() }}
                    >
                        <img src={backToIcon} style={{ width: phone ? 24 : 32, marginRight: 4 }} />


                    </Button>
                </div>
                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: phone ? 12 : 36 }} />

                <Typography
                    style={
                        phone ? { marginTop: 10, color: "#858E9D", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                            : { marginTop: 15, color: "#858E9D", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                    }
                >
                    {t("confirmFundContent")}
                </Typography>
                <Typography
                    style={
                        phone ? { marginTop: 10, color: "#0173F9", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                            : { marginTop: 10, color: "#0173F9", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                    }
                >
                    {t("confirmFundContent2")}
                </Typography>
                <Typography
                    style={
                        phone ? { marginTop: 10, color: "#0173F9", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                            : { marginTop: 10, color: "#0173F9", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                    }
                >
                    {t("confirmFundContent3")}
                </Typography>
                <Typography
                    style={
                        phone ? { marginTop: 10, color: "#0173F9", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                            : { marginTop: 10, color: "#0173F9", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                    }
                >
                    {t("confirmFundContent4")}
                </Typography>
                <div style={{ marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    {/* <div >
                        <TextField
                            label={t('pay')}
                            defaultValue={`${depositAmount} ${currency}`}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                style: {
                                    paddingTop: phone ? 0 : 5,
                                    fontWeight: '500',
                                    color: 'black',
                                    fontSize: phone ? 12 : 18
                                },
                                endAdornment: currency === '' ? null : <Avatar style={{ marginRight: 5, width: phone ? 16 : 24, height: phone ? 16 : 24, borderRadius: 5, alignSelf: 'center' }} src={getIcons(currency)} variant="square" />
                            }}
                            InputLabelProps={{
                                style: {
                                    padding: phone ? '12px 20px' : 20,
                                    fontSize: 18,
                                    fontWeight: '500',
                                    color: '#9097A1'
                                },
                            }}
                            className={phone ? classes.confirmingPhone : classes.confirming}
                            style={{ color: 'black' }}
                        />
                    </div> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                        <Typography className={classes.confirmListTitle}>{t('pay')}</Typography>
                        <Typography className={classes.confirmListText}>{depositAmount} {currency}</Typography>
                    </div>
                    {/*{*/}
                    {/*    eTransferring ?*/}
                    {/*        // <div style={{ width: '100%', marginTop: 40, marginBottom: 40 }} >*/}
                    {/*        //     <Typography*/}
                    {/*        //         style={*/}
                    {/*        //             phone ? { marginTop: 5, color: "#1C2237", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }*/}
                    {/*        //                 : { marginTop: 10, color: "#1C2237", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }*/}
                    {/*        //         }*/}
                    {/*        //     >*/}
                    {/*        //         {t("eTransferHead")}*/}
                    {/*        //     </Typography>*/}
                    {/*        //     <TextField*/}
                    {/*        //         defaultValue={`${userInfo.username}`}*/}
                    {/*        //         InputProps={{*/}
                    {/*        //             readOnly: true,*/}
                    {/*        //             disableUnderline: true,*/}
                    {/*        //             style: {*/}
                    {/*        //                 fontWeight: '500',*/}
                    {/*        //                 color: '#9097A1',*/}
                    {/*        //                 fontSize: 18*/}
                    {/*        //             },*/}
                    {/*        //         }}*/}
                    {/*        //         className={phone ? classes.emailPhone : classes.email}*/}
                    {/*        //         style={{ color: 'black' }}*/}
                    {/*        //     />*/}
                    {/*        // </div>*/}
                    {/*        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>*/}
                    {/*            <Typography className={classes.confirmListTitle}>{t("eTransferHead")}</Typography>*/}
                    {/*            <Typography className={classes.confirmListText}>{userInfo.username}</Typography>*/}
                    {/*        </div>*/}
                    {/*        :*/}
                    {/*        null*/}
                    {/*}*/}
                    {
                        depositMsg === '' ? null :
                            <Typography
                                style={
                                    phone ? { marginTop: 10, color: "red", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                                        : { marginTop: 10, color: "red", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                                }
                            >
                                {t(depositMsg)}
                            </Typography>
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 60 }}>
                    <Button
                        className={phone ? `${classes.buttonPhone} ${classes.nextPhone}` : `${classes.button} ${classes.next}`}
                        style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded || kycDetails.status !== 'passed' ? 0.3 : 1 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { submit() }}
                    >
                        {t('send')}
                    </Button>

                </div>
            </div>
        )
    }

    const renderSubmitted = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', alignItems: 'center' }}>
                    <img style={{ width: phone ? 24 : 32, height: phone ? 24 : 32, borderRadius: phone ? 8 : 12, alignSelf: 'center', backgroundColor: '#F9F9F9', padding: phone ? 6 : 12, marginRight: 8 }} src={getIcons(currency)} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: phone ? 4 : 12 }}>
                        <Typography

                            className={classes.submittedTitleText}
                            style={{ fontSize: phone ? 14 : 20, fontWeight: '500', marginTop: 0, fontFamily: 'Avenir', }}

                        >
                            {t("confirmed")}
                        </Typography>
                        {/* <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 14, fontWeight: '400' }} >
                            {t("subtitleSuccess")}
                        </Typography> */}
                    </div>
                </div>
                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: phone ? 12 : 0 }} />

                {/* <Typography
                    style={
                        phone ? { marginTop: 40, color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                            : { marginTop: 60, color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                    }
                >
                    {t("confirmed")}
                </Typography> */}

                {/* <Typography
                    style={
                        phone ? {marginTop:10, color:"#0173F9", fontFamily:"Avenir", fontSize:12, fontWeight:500, textAlign:'initial'}
                            : {marginTop:15, color:"#0173F9", fontFamily:"Avenir", fontSize:14, fontWeight:500, textAlign:'initial'}
                    }
                >
                    {eTransferring ? t("confirmedContentETransfer") : t('confirmedContentCSupport')}
                </Typography> */}
                {
                    eTransferring ?

                        <Typography
                            className={classes.submittedTitleText}

                        >
                            {t("confirmedContentETransfer")}
                        </Typography>

                        :
                        <span
                            className={classes.submittedTitleText}

                        >
                            {t('confirmedContentCSupport1')}
                            <span style={{ color: '#0173F9' }}>+1 (647) 9476258</span>
                            <span style={{ color: '#858E9D' }}> {t('confirmedContentCSupport2')}</span>
                        </span>
                }
                <div style={{ marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    <List style={{ width: '100%' }} >
                        <ListItem className={classes.confirmList} >
                            <ListItemText
                                disableTypography
                                className={classes.confirmListTitle}
                                primary={t('paymentMethod')}
                            />
                            <ListItemSecondaryAction>
                                <Typography className={classes.confirmListText}>{eTransferring ? t('InteracETransfer') : t('CustomerSupport')}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                        {
                            eTransferring ?
                                <ListItem className={classes.confirmList} >
                                    <ListItemText
                                        disableTypography
                                        className={classes.confirmListTitle}
                                        primary={t('eTransferHead')}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography className={classes.confirmListText}>{userInfo.username}</Typography>
                                    </ListItemSecondaryAction>
                                </ListItem> :
                                <ListItem className={classes.confirmList} >
                                    <ListItemText
                                        disableTypography
                                        className={classes.confirmListTitle}
                                        primary={t('phone')}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography className={classes.confirmListText}>{userInfo.verificationPhone}</Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                        }
                        <ListItem className={classes.confirmList} >
                            <ListItemText
                                disableTypography
                                className={classes.confirmListTitle}
                                primary={t('totalPayment')}
                            />
                            <ListItemSecondaryAction>
                                <Typography className={classes.confirmListText}>{`$${depositAmount} ${currency}`}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                        {
                            eTransferring ?
                                <ListItem className={classes.confirmList} >
                                    <ListItemText
                                        disableTypography
                                        className={classes.confirmListTitle}
                                        primary={t('validTime')}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography style={{ color: '#DC3055' }} className={classes.confirmListText}>{formDateString(validDate.moneyReqExpDate)}</Typography>
                                    </ListItemSecondaryAction>
                                </ListItem> : null
                        }
                    </List>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                        className={phone ? `${classes.buttonPhone} ${classes.nextPhone}` : `${classes.button} ${classes.next}`}
                        style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded || kycDetails.status !== 'passed' ? 0.3 : 1 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { goBackFromSubmitted() }}
                    >
                        {t('done')}
                    </Button>

                    <div style={{ height: 80 }}></div>
                </div>
            </div>
        )
    }

    const cryptoModalBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'

            }}>
                <div style={{ width: '91%', marginTop: '7%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: "500", color: '#1C2237', fontFamily: 'Avenir', }}>
                        {t('selectCoin')}
                    </Typography>
                    <Button
                        // disabled={fundActionLoading || !loaded}
                        onClick={() => { handleClose() }}
                        style={{
                            display: 'inline-block',
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        variant="square"
                    >
                        <img src={closePopIcon} style={{ width: phone ? 32 : 40 }} />


                    </Button>
                </div>
                <div style={{ height: 1, width: '91%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 12 }} />

                {/* <Typography style={{ fontSize: phone ? 14 : 24, fontWeight: 500, margin: '7%', color: '#1C2237' }}>
                {t('selectCoin')}
            </Typography> */}
                <div style={{ justifyContent: 'space-between', display: 'flex', width: '91%' }}>
                    <List style={{ width: '100%' }} >
                        {tokenList.map(item => (
                            <ListItem onClick={() => {
                                if (item.open !== false) {
                                    handleCoinChange(item.token)
                                    setShowCryptoModal(false)
                                }
                            }} key={item.token} style={{ backgroundColor: coin === item.token ? '#F9F9F9' : 'white', marginBottom: 15, borderRadius: phone ? 12 : 20, paddingLeft: phone ? 8 : 20, paddingRight: phone ? 0 : 20, opacity: item.open ? 1 : 0.4, height: phone ? 28 : 60 }}
                            >
                                <ListItemAvatar style={{ minWidth: 30 }}>
                                    <img style={{ width: phone ? 20 : 32, height: phone ? 20 : 32, alignSelf: 'center', padding: 4 }} src={getIcons(item.token)} variant="square" />

                                </ListItemAvatar>
                                <div style={{ marginLeft: phone ? 0 : 8 }}>
                                    <Typography style={{ fontSize: phone ? 14 : 20, fontWeight: '500', fontFamily: 'Avenir', }}>{item.token}</Typography>


                                    {/* <span
                                    style={{ color: "#858E9D", fontSize: phone ? 10 : 14, fontWeight: 500, textAlign: 'initial', float: 'right' }}
                                >
                                    {t('Balance')}
                                    <span style={{ color: '#0173F9', marginLeft: 4, marginRight: 4 }}>{roundingDown(item.amount, 8)} </span>
                                    <span style={{ color: '#858E9D' }}> {item.token}</span>
                                </span> */}
                                </div>
                                {/* <ListItemText
                                primary={item.token}
                                className={classes.listItem}
                                style={{ paddingLeft: 15, color: coin === item.token ? '#0173F9' : '#1C2237' }}
                            /> */}
                                {
                                    coin === item.token ?
                                        <ListItemSecondaryAction>
                                            <img style={{ height: phone ? 24 : 32, alignSelf: 'center' }} src={tickIcon} />
                                        </ListItemSecondaryAction> : null
                                }
                                {
                                    item.open ? null :
                                        <ListItemSecondaryAction>
                                            <Typography style={{ color: '#858E9D', fontSize: 14, fontStyle: 'oblique', fontWeight: 500, fontFamily: 'Avenir', }}>{t('notAvailable')}</Typography>
                                        </ListItemSecondaryAction>
                                }
                            </ListItem>
                        )
                        )}
                    </List>
                </div>
                <div style={{ height: 40 }} />
                {/* <div className="col" style={{ width: '100%' }}>
                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ backgroundColor: 'transparent', color: "#1C2237", marginTop: phone ? 10 : 20 }}
                    onClick={handleClose}
                >
                    <img src={cancel} style={{ alignSelf: 'center', width: 15, paddingRight: 5 }} />
                    {t('cancel')}
                </Button>
            </div> */}
            </div>
        </motion.div>
    )

    const networkModalBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
            }}>
                <div style={{ width: '91%', marginTop: '7%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: "500", color: '#1C2237' }}>
                        {t('selectChain')}
                    </Typography>
                    <Button
                        // disabled={fundActionLoading || !loaded}
                        onClick={() => { handleClose() }}
                        style={{
                            display: 'inline-block',
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        variant="square"
                    >
                        <img src={closePopIcon} style={{ width: phone ? 32 : 40 }} />


                    </Button>
                </div>
                <div style={{ height: 1, width: '91%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 12 }} />

                <div style={{ justifyContent: 'space-between', display: 'flex', width: '91%' }}>
                    <List style={{ width: '100%' }} >
                        {chainList.map(item => (
                            <ListItem onClick={() => {
                                handleChainChange(item.chain)
                                setShowNetworkModal(false)
                            }} key={item.chain} style={{ backgroundColor: chain === item.chain ? '#F9F9F9' : 'white', alignItems: 'center', marginBottom: 15, borderRadius: phone ? 12 : 20, paddingLeft: phone ? 8 : 20, paddingRight: phone ? 0 : 20, height: phone ? 28 : 60 }}>
                                {/* <ListItemText
                                primary={item.chain}
                                className={classes.listItem}
                                style={{ color: chain === item.chain ? '#0173F9' : '#1C2237' }}
                            /> */}
                                <div style={{ marginLeft: phone ? 0 : 8 }}>
                                    <Typography style={{ fontSize: phone ? 14 : 20, fontWeight: '500' }}>{item.chain === 'TRX' ? 'TRC20' : item.chain}</Typography>
                                </div>
                                {
                                    chain === item.chain ?
                                        <ListItemSecondaryAction>
                                            <img style={{ height: phone ? 24 : 32, alignSelf: 'center' }} src={tickIcon} />
                                        </ListItemSecondaryAction> : null
                                }
                            </ListItem>
                        )
                        )}
                    </List>
                </div>
                {/* <div className="col" style={{ width: '100%' }}>
                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ backgroundColor: 'transparent', color: "#1C2237", marginTop: phone ? 10 : 20 }}
                    onClick={handleClose}
                >
                    <img src={cancel} style={{ alignSelf: 'center', width: 15, paddingRight: 5 }} />
                    {t('cancel')}
                </Button>
            </div> */}
                <div style={{ height: 40 }} />

            </div>
        </motion.div>
    )

    const qrModalBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: '#FFFFFF', borderRadius: 24, width: 272, height: 272, overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column',
                justifyContent: 'center', padding: '12px 12px 0px 12px'
            }}>
                {/*<div style={{justifyContent:'space-between', display:'flex' }}>*/}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <QRCodeSVG value={address} size={178} />
                </div>
                <div className="col" style={{ width: '100%', paddingTop: 12 }}>
                    <Button
                        className={classes.buttonPhone}
                        style={{ backgroundColor: 'transparent', color: "#1C2237", fontSize: 14, height: 19, }}
                        onClick={handleClose}
                    >
                        <img src={cancel} style={{ alignSelf: 'center', width: 15, paddingRight: 5 }} />
                        {t('cancel')}
                    </Button>
                </div>
                {/*</div>*/}
            </div>
        </motion.div>
    )

    return (

        <MuiThemeProvider theme={defaultTheme}>
            <Helmet>
                <title>{t("titleFund")}</title>
                <meta name="keywords" content="Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇, 加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣， Yesbit, Fiat, CAD, BTC, ETH, USD, balance, funding, deposit, 充值" />
            </Helmet>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ width: phone ? '92%' : '60%', alignItems: 'center', textAlign: 'center', maxWidth: 543, minWidth: 317 }}>
                    {loaded && selectingFundType ? renderSelectFundType() : null}
                    {loaded && depositingCrypto ? renderDepositCrypto() : null}
                    {loaded && inputting ? renderInputting() : null}
                    {loaded && confirming ? renderConfirming() : null}
                    {loaded && submitted ? renderSubmitted() : null}
                </div>
                <Modal
                    open={showCryptoModal}

                    onClose={() => {
                        setShowCryptoModal(false)
                    }}
                    style={{ alignItems: "center", display: 'flex', justifyContent: 'center' }}
                >
                    {cryptoModalBody}
                </Modal>
                <Modal

                    open={showNetworkModal}
                    // open={true}

                    onClose={() => {
                        setShowNetworkModal(false)
                    }}
                    style={{ alignItems: "center", display: 'flex', justifyContent: 'center' }}
                >
                    {networkModalBody}
                </Modal>
                <Modal
                    open={showQrModal}
                    onClose={() => {
                        setShowQrModal(false)
                    }}
                    style={{ alignItems: "center", display: 'flex', justifyContent: 'center' }}
                >
                    {qrModalBody}
                </Modal>
            </div>
        </MuiThemeProvider>
    )
}
export default withTranslation()(Fund)
