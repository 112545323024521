import React, { useEffect, useState } from 'react'
import { List, ListItem, ListItemAvatar, ListItemSecondaryAction, makeStyles, Typography, Paper, Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import promotionEN from '../../../images/trade/promotion_en.png'
import promotionCN from '../../../images/trade/promotion_cn.png'
import referralBanner from '../../../images/trade/referralPlanBG.png'
import { useHistory } from "react-router-dom";
import { getIcons, roundingDown } from "../../../utils/Common";
import { tradeActions } from "../../../redux/actions/tradeActions";
import { fundsActions } from "../../../redux/actions/fundsActions";
import Carouse from '../../../components/Carousel'
import i18n from "../../../i18n";


function TradeInfo({ t, phone, responsive, quote, dispatch }) {
    const { token } = useSelector(state => state.auth);
    const { kycDetails, documentDetails } = useSelector(state => state.kyc)
    const { userCapitals, priceQuote, ethQuote, btcQuote } = useSelector(state => state.funds);
    const { ethPriceChange, btcPriceChange, usdtPriceChange, tradeBannerInfo } = useSelector(state => state.trade)

    const [priceInfo, setPriceInfo] = useState([
        {
            token1: 'USDT', priceChange: '0.001',
            priceChangePercent: '0.00', quote: ethQuote
        },
        {
            token1: 'ETH', priceChange: '0.001',
            priceChangePercent: '0.00', quote: priceQuote
        },
        {
            token1: 'BTC', priceChange: '0.001',
            priceChangePercent: '0.00', quote: btcQuote
        },

    ])

    useEffect(() => {
        dispatch(tradeActions.getPriceChange({
            token1: 'ETH',
            token2: 'USDT'
        }, token))

        dispatch(tradeActions.getPriceChange({
            token1: 'BTC',
            token2: 'USDT'
        }, token))

        dispatch(tradeActions.getPriceChange({
            token1: 'USDT',
            token2: 'CAD'
        }, token))

        dispatch(tradeActions.getBannerInfo(token))

    }, [])

    useEffect(() => {
        arrangePriceInfo(ethPriceChange)
    }, [ethPriceChange])

    useEffect(() => {
        arrangePriceInfo(btcPriceChange)
    }, [btcPriceChange])

    useEffect(() => {
        arrangePriceInfo(usdtPriceChange)
    }, [usdtPriceChange])

    const arrangePriceInfo = (priceChange) => {
        let temInfo = priceInfo
        let index = temInfo.findIndex(item => item.token1 === priceChange.token1)
        if (temInfo !== undefined) {
            temInfo[index] = priceChange
            setPriceInfo(temInfo)
        }
    }

    const useStyles = makeStyles((theme) => ({
        indCircalActive: {
            height: phone ? 24 : 38, width: phone ? 24 : 38, borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'
        },
        indCircalTextActive: {
            fontSize: phone ? 12 : 16, fontWeight: "500", fontFamily: 'Avenir'
        },
        indCircalInactive: {
            height: phone ? 24 : 38, width: phone ? 24 : 38, borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'
        },
        indCircalTextInactive: {
            fontSize: phone ? 12 : 16, fontWeight: "500", fontFamily: 'Avenir'
        },
        tableCell: {
            backgroundColor: '#F9F9F9',
            borderRadius: 20,
            padding: '17px 20px',
            margin: phone ? '12px 0px' : '12px 0px',
            height: 80,
            width: "100%"
        },
        arrowUp: {
            width: 0,
            height: 0,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',

            borderBottom: '6px solid #39C55E',
            marginRight: 2
        },
        arrowDown: {
            width: 0,
            height: 0,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            borderTop: '6px solid #D9595C',
            marginRight: 2
        }
    }))

    const classes = useStyles();

    const history = useHistory();

    const marketTable = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: phone ? "100%" : 425 }}>
                <Typography style={{ marginTop: phone ? 24 : 0, marginLeft: 0, color: '#8B96A7', fontFamily: 'Avenir', fontSize: 12, fontWeight: 500 }}>
                    {t('market')}
                </Typography>
                <List style={{ width: '100%' }} >
                    {priceInfo.map(item => (
                        <ListItem key={item.token1 + item.priceChangePercent} className={classes.tableCell}
                        >
                            <ListItemAvatar style={{ minWidth: 49, backgroundColor: '#E4E4E4', borderRadius: phone ? 12 : 16, height: 49, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img style={{ height: phone ? 28 : 32, alignSelf: 'center' }} src={getIcons(item.token1)} />
                            </ListItemAvatar>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Typography style={{ fontSize: 16, marginLeft: 8, color: '#1C2237', fontWeight: '500', fontFamily: 'Avenir', }}>{item.token1}</Typography>
                                <Typography style={{ fontSize: 14, marginLeft: 8, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', }}>{item.token1 === 'ETH' ? 'Ethereum' : item.token1 === 'BTC' ? 'Bitcoin' : 'Tether USD'}</Typography>
                            </div>
                            <ListItemSecondaryAction>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: 20, fontSize: 14, fontWeight: 500, fontFamily: 'Avenir' }}>
                                    <Typography style={{ fontSize: phone ? 16 : 20, color: 'black', fontWeight: '500', fontFamily: 'Avenir', }}> {`$${roundingDown(quote[item.token1].price, 4)}`}</Typography>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className={item.priceChangePercent >= 0 ? classes.arrowUp : classes.arrowDown}></div>
                                        <Typography style={{ color: item.priceChangePercent >= 0 ? '#39C55E' : '#D9595C', fontSize: 14, fontWeight: '500', fontFamily: 'Avenir', }}> {`${item.priceChangePercent}%`}</Typography>
                                    </div>

                                </div>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                    )}
                </List>
            </div>

        )
    }

    return (
        <div
            style={
                { display: 'flex', flexDirection: 'column', justifyContent: phone ? 'center' : 'flex-start', alignItems: 'center', height: '100%', maxWidth: 630 }
            }
        >
            <div style={{ cursor: 'pointer', backgroundSize: 'contain', width: phone ? "100%" : 425, height: 230, boxShadow: 'unset', marginBottom: 24 }}>

                <Carouse>
                    {
                        tradeBannerInfo.length > 0 && tradeBannerInfo != [] ?
                            tradeBannerInfo.map(item => (
                                <div
                                    key={item.cnBannerLink}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                    onClick={() => window.open(i18n.language === 'cn' ? item.cnBannerLink === '' || item.cnBannerLink === null ? '' : item.cnBannerLink : item.cnBannerLink === '' || item.cnBannerLink === null ? '' : item.engBannerLink, "_blank")} >
                                    <img style={{ objectFit: 'cover', borderRadius: 20, cursor: 'pointer', width: "100%", height: '100%', boxShadow: 'unset' }} src={i18n.language === 'en' ? item.engBannerUri : item.cnBannerUri} alt="new" />
                                </div>

                            ))
                            :



                            <img src={i18n.language === 'cn' ?
                                promotionCN : promotionEN}
                                style={{ cursor: 'pointer', width: "100%", height: 201, boxShadow: 'unset', borderRadius: 20 }}
                                onClick={() => {
                                    window.open(i18n.language === 'cn' ? 'https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/' : 'https://otcdocs.yesbit.io/')
                                }}
                            >

                            </img>
                    }

                </Carouse>


            </div>
            {marketTable()}
            <div style={{ backgroundSize: "contain", width: phone ? '100%' : 425, marginTop: phone ? 0 : 24, borderRadius: 20, backgroundColor: '#003FFF', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }} onClick={() => {
                history.push('/referral')
            }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, marginBottom: 16 }}>
                    <div style={{ width: '60%' }}>
                        <Typography style={{ marginLeft: 24, fontSize: 20, fontFamily: 'Avenir', fontWeight: 500, color: 'white' }}>{t("tradeInfoReferralTitle")}</Typography>
                        <Typography style={{ marginLeft: 24, fontSize: 14, fontFamily: 'Avenir', fontWeight: 500, color: 'white' }}>{t("tradeInfoReferralSubTitle")}</Typography>
                        <Button style={{ marginLeft: 24, marginTop: 16, height: 32, width: 99, textTransform: 'unset', backgroundColor: 'white', borderRadius: 9, fontSize: 15, fontWeight: 500, fontFamily: 'Avenir', color: '#003FFF' }} >{t('toInvite')}</Button>
                    </div>
                    <img style={{ width: '40%' }} src={referralBanner}></img>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(TradeInfo);
